/* eslint-disable default-param-last */
import {
  DISPLAY_ASYNC_ERROR,
  CLEAR_ASYNC_ERRORS,
  SET_ERROR,
  START_ASYNC_REQUEST,
  SET_ASYNC_SUCCESS_STATUS,
  SET_ASYNC_FAILED_STATUS,
  CLEAR_ASYNC_STATUS,
  SHOW_MODAL,
  TOGGLE_MODAL,
  SET_FORM_ERROR,
  CLEAR_FORM_ERROR,
  SET_ERROR_LEVEL,
  UPDATE_APP,
  TOGGLE_LOADER,
  CHECK_FOR_GMAIL_ADDRESS,
  SET_SECTION,
  REDIRECT,
  CLEAR_REDIRECT,
  SET_MANUAL_ADDRESS_FLAG,
  GET_LEAD_DATA,
  GET_LEAD_DATA_FAILURE,
  GET_LEAD_DATA_SUCCESS,
  SAVE_DL_INFO,
  // ENABLE_PRE_BIND_MODE,
  SAVE_CRM_GATE,
  SAVE_CRM_GATE_SUCCESS,
  SAVE_CRM_GATE_FAILURE,
  RETRIEVE_QUOTE,
  RETRIEVE_QUOTE_SUCCESS,
  RETRIEVE_QUOTE_FAILURE,
  RETRIEVE_USAA_PERSON_INFO,
  RETRIEVE_USAA_PERSON_INFO_SUCCESS,
  RETRIEVE_USAA_PERSON_INFO_FAILURE,
  VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE,
  VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_SUCCESS,
  VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_FAILURE,
  GET_CHECKPOINT,
  GET_CHECKPOINT_SUCCESS,
  SHOW_EMAIL_MODAL,
  HIDE_EMAIL_MODAL,
  REGISTER_QUOTE,
  REGISTER_QUOTE_SUCCESS,
  REGISTER_QUOTE_FAILURE,
  REQUEST_USAA_ELIGIBILITY_QUESTIONS,
  REQUEST_USAA_ELIGIBILITY_QUESTIONS_SUCCESS,
  REQUEST_USAA_ELIGIBILITY_QUESTIONS_FAILURE,
  SUBMIT_PRIMARY_ELIGIBILITY_ANSWER,
  SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_SUCCESS,
  SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_FAILURE,
  SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS,
  SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_SUCCESS,
  SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_FAILURE,
  REQUEST_PREFILL,
  REQUEST_PREFILL_SUCCESS,
  REQUEST_PREFILL_FAILURE,
  CLEAR_USAA_ELIGIBILITY_QUESTIONS,
  VERIFY_DRIVERS_LICENSES,
  VERIFY_DRIVERS_LICENSES_SUCCESS,
  VERIFY_DRIVERS_LICENSES_FAILURE,
  VERIFY_DRIVERS_LICENSES_ERROR,
  UPDATE_TO_TRIAL_SUCCESS,
  UPDATE_DEFENSIVE_DRIVING_ANSWER,
  UPDATE_DEFENSIVE_DRIVING_ANSWER_SUCCESS,
  UPDATE_DEFENSIVE_DRIVING_ANSWER_FAILURE,
  UPDATE_PRIOR_INSURANCE_LAPSE_REASON,
  UPDATE_PRIOR_INSURANCE_LAPSE_REASON_SUCCESS,
  UPDATE_PRIOR_INSURANCE_LAPSE_REASON_FAILURE,
  REQUEST_PRIOR_INSURANCE_REASONS,
  REQUEST_PRIOR_INSURANCE_REASONS_SUCCESS,
  REQUEST_PRIOR_INSURANCE_REASONS_FAILURE,
  SET_RIDESHARE_TNC_STATUS,
  SET_RIDESHARE_TNC_STATUS_SUCCESS,
  SET_RIDESHARE_TNC_STATUS_FAILURE,
  CLEAR_APP_ERRORS,
  CONFIRMED_PII,
  ASK_MILITARY_SERVICE_VEHICLES,
  OPEN_MODAL,
  CLOSE_MODAL,
  SUBMIT_FORM_ID_SIGNATURE_SUCCESS,
  ASK_DEFENSIVE_DRIVING_IN_DRIVER_EXCLUSION
} from '../actions';

const INITIAL_APP_STATE = {
  section: 'start-quote',
  prevSection: null,
  errorMessage: '',
  error: null,
  asyncRequest: false,
  currentStep: '',
  redirect: null,
  hasPartnerData: false,
  isQuoteRetrieval: false,
  manualAddress: false,
  rehydrated: false,
  registeredQuote: false,
  confirmedPII: {
    dlNumber: false,
    dob: false,
    street: false,
    autoCompleteAddress: false
  },
  trialActive: false,
  sendingCRMGate: false,
  crmGateError: null,
  isEmailModalOpen: false,
  loading: false,
  leadType: null,
  leadId: null,
  isDNQ: false,
  askOwnershipLength: false,
  askSeniorDriverImprovement: false,
  askSafeDriving: false,
  askEmergencyBraking: false,
  askMilitaryServiceVehicles: false,
  askVinEtching: false,
  askCommuteSurcharge: false,
  askActiveMilitaryPersonnel: false,
  askGoodStudentDiscount: false,
  requiresVehicleAssignment: false,
  askAirbagVerification: false,
  askPriorInsuranceLapseReason: false, // specific to GA
  requiresUSAAEligibility: true, // ** FALSE FOR USAA QUOTE INTEGRATION FLOW **
  isUSAAMember: false,
  eligibilityQuestions: {
    primaryEligibility: null,
    secondaryEligibility: null
  },
  prefillComplete: false,
  requiresDLVerification: true,
  dlVerificationStatus: 'unverified',
  verificationError: null, // ** SPECIFIC TO DL VERIFICATION
  policyActive: false,
  form: { error: null },
  defensiveCourseTaken: null,
  numberOfDaysWithoutInsurance: null, // specific to GA
  priorInsuranceLapseReason: null, // specific to GA
  priorInsuranceLapseOptions: [], // specific to GA
  veriskHit: false, // true if prefill returns driver DL number
  requiresDynamicForms: false, //  ** Dynamic Pre-Bind Forms flag **
  requiresUmuimOfferForm: false, // specific to AZ, GA and NV
  requiresMedPayOfferForm: false, // specific to NV,
  usaaPersonInfoRetrieved: false, // USAA Quote Flow Integration
  usaaPersonInfoVerified: false, // ** FLAG FOR USAA QUOTE INTEGRATION FLOW**,
  requiresDriverExclusion: false, // ** DRIVER EXCLUSION FLAG **
  isModalOpen: false,
  openModalName: null,
  allVehiclesOwnedByAmp: null,
  askDefensiveDrivingInDriverExclusion: false // specific GA flag for driver exclusion
};

const appReducer = (state = INITIAL_APP_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MANUAL_ADDRESS_FLAG:
      return {
        ...state,
        manualAddress: payload
      };

    case UPDATE_APP:
      return {
        ...state,
        ...payload
      };

    case CONFIRMED_PII:
      return {
        ...state,
        ...payload,
        confirmedPII: {
          ...state.confirmedPII,
          ...payload.confirmedPII
        }
      };

    case SET_SECTION:
      return {
        ...state,
        prevSection:
          state.section === payload.section &&
          state.prevSection !== payload.section
            ? state.prevSection
            : state.section,
        section: payload.section
      };

    case CHECK_FOR_GMAIL_ADDRESS:
      return {
        ...state,
        showGoogleSignup: payload.showGoogleSignup
      };

    case GET_LEAD_DATA:
      return {
        ...state,
        ...payload
      };

    case GET_LEAD_DATA_FAILURE:
      return {
        ...state,
        hasPartnerData: false,
        loading: false
      };

    case GET_LEAD_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
        hasPartnerData: true,
        loading: false
      };

    case SET_ERROR:
      return {
        ...state,
        error: { ...action.error },
        loading: false
      };

    case SET_ERROR_LEVEL:
      return {
        ...state,
        error: { ...action.error },
        isDNQ: action.error.DNQ && action.error.DNQ.length > 0,
        loading: false
      };

    case SET_FORM_ERROR:
      return {
        ...state,
        form: { error: action.error }
      };

    case CLEAR_FORM_ERROR:
      return {
        ...state,
        form: { error: null }
      };

    case SHOW_MODAL:
      return {
        ...state,
        modal: {
          [payload]: true
        }
      };

    case TOGGLE_LOADER:
      return {
        ...state,
        loading: payload.toShow
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        modal: {
          [payload]: !state.modal[payload]
        }
      };

    case DISPLAY_ASYNC_ERROR:
      return {
        ...state,
        error: action.error,
        errorMessage: action.error.message || payload
      };

    case CLEAR_ASYNC_ERRORS:
      return {
        ...state,
        errorMessage: '',
        error: null
      };

    case START_ASYNC_REQUEST:
      return {
        ...state,
        asyncRequest: 'pending',
        endpointUrl: payload
      };

    case SET_ASYNC_SUCCESS_STATUS:
      return {
        ...state,
        asyncRequest: 'success'
      };

    case SET_ASYNC_FAILED_STATUS:
      return {
        ...state,
        asyncRequest: 'fail'
      };

    case CLEAR_ASYNC_STATUS:
      return {
        ...state,
        asyncRequest: false,
        endpointUrl: ''
      };

    case REDIRECT:
      return {
        ...state,
        redirect: { url: payload.url, config: payload.config }
      };

    case CLEAR_REDIRECT:
      return {
        ...state,
        redirect: null
      };

    case SAVE_DL_INFO:
      return {
        ...state,
        confirmedPII: {
          ...state.confirmedPII,
          dlNumber: true
        }
      };

    // case ENABLE_PRE_BIND_MODE:
    //   return {
    //     ...state,
    //     section: 'pre-bind',
    //     currentStep: 'begin',
    //     hasPartnerData: false,
    //     isQuoteRetrieval: false,
    //     rehydrated: false,
    //     isPreBind: true
    //   };

    case SAVE_CRM_GATE:
      return {
        ...state,
        sendingCRMGate: true,
        crmGateError: null
      };

    case RETRIEVE_QUOTE:
      return {
        ...state,
        isQuoteRetrieval: true,
        section: 'quote',
        prevSection: null,
        loading: true,
        form: { error: {} }
      };

    case RETRIEVE_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case RETRIEVE_QUOTE_FAILURE:
      return {
        ...state,
        form: { error: { ...action.error } },
        loading: false
      };

    case RETRIEVE_USAA_PERSON_INFO:
      return {
        ...state,
        loading: true,
        section: 'start-quote',
        form: { error: null }
      };

    case RETRIEVE_USAA_PERSON_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        usaaPersonInfoRetrieved: true,
        requiresUSAAEligibility: false,
        isUSAAMember: true
      };

    case RETRIEVE_USAA_PERSON_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        usaaPersonInfoRetrieved: false,
        form: { error: { ...action.error } }
      };

    case VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE:
      return {
        ...state,
        loading: true,
        form: { error: null }
      };

    case VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        usaaPersonInfoVerified: true,
        confirmedPII: {
          ...state.confirmedPII,
          dob: true,
          street: true
        }
      };

    case VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        usaaPersonInfoVerified: false,
        error: true,
        errorMessage: action.error.message,
        form: { error: { message: action.error.message } }
      };

    case GET_CHECKPOINT:
      return {
        ...state,
        loading: true
      };

    case GET_CHECKPOINT_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case SAVE_CRM_GATE_SUCCESS:
      return {
        ...state,
        sendingCRMGate: false
      };

    case SAVE_CRM_GATE_FAILURE: {
      return {
        ...state,
        sendingCRMGate: false,
        crmGateError: action.error
      };
    }

    case SHOW_EMAIL_MODAL:
      return {
        ...state,
        isEmailModalOpen: true
      };

    case HIDE_EMAIL_MODAL:
      return {
        ...state,
        isEmailModalOpen: false
      };

    case REGISTER_QUOTE:
      return {
        ...state,
        registeredQuote: 'pending'
      };

    case REGISTER_QUOTE_SUCCESS:
      return {
        ...state,
        registeredQuote: true
      };

    case REGISTER_QUOTE_FAILURE:
      return {
        ...state,
        registeredQuote: false
      };

    case REQUEST_USAA_ELIGIBILITY_QUESTIONS:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null
      };

    case REQUEST_USAA_ELIGIBILITY_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        eligibilityQuestions: payload
      };

    case REQUEST_USAA_ELIGIBILITY_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        errorMessage: action.error.message
      };

    case SUBMIT_PRIMARY_ELIGIBILITY_ANSWER:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
        eligibilityQuestions: {
          ...state.eligibilityQuestions,
          primaryEligibility: state.eligibilityQuestions.primaryEligibility.map(
            question => ({
              ...question,
              lastSavedValue: payload[question.eligibilityQuestionId]
            })
          )
        }
      };

    case SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_SUCCESS:
      return {
        ...state,
        isUSAAMember: payload.isUSAAMember,
        loading: false
      };

    case SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_FAILURE:
      return {
        ...state,
        error: action.error,
        errorMessage: action.error.message,
        loading: false
      };

    case SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS:
      return {
        ...state,
        eligibilityQuestions: {
          ...state.eligibilityQuestions,
          secondaryEligibility:
            state.eligibilityQuestions.secondaryEligibility.map(question => ({
              ...question,
              lastSavedValue: payload[question.eligibilityQuestionId]
            }))
        },
        loading: true,
        error: null,
        errorMessage: null
      };

    case SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_SUCCESS:
      return {
        ...state,
        isUSAAMember: payload,
        loading: false
      };

    case SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        errorMessage: action.error.message
      };

    case REQUEST_PREFILL:
      return {
        ...state,
        loading: true,
        prefillComplete: false,
        error: null,
        errorMessage: null
      };

    case REQUEST_PREFILL_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        prefillComplete: true
      };

    case REQUEST_PREFILL_FAILURE:
      return {
        ...state,
        loading: false,
        prefillComplete: false,
        error: action.error,
        errorMessage: action.error.message
      };

    case CLEAR_USAA_ELIGIBILITY_QUESTIONS:
      return {
        ...state,
        eligibilityQuestions: {
          primaryEligibility: null,
          secondaryEligibility: null
        }
      };

    case VERIFY_DRIVERS_LICENSES:
      return {
        ...state,
        loading: true,
        dlVerificationStatus: 'pending',
        verificationError: null
      };

    case VERIFY_DRIVERS_LICENSES_SUCCESS:
      return {
        ...state,
        confirmedPII: {
          ...state.confirmedPII,
          dlNumber: true
        },
        loading: false,
        requiresDLVerification: false,
        dlVerificationStatus: 'success'
      };

    case VERIFY_DRIVERS_LICENSES_FAILURE:
      return {
        ...state,
        loading: false,
        dlVerificationStatus: 'failed'
      };

    case VERIFY_DRIVERS_LICENSES_ERROR:
      return {
        ...state,
        dlVerificationStatus: 'error',
        loading: false,
        verificationError: action.error
      };

    case UPDATE_TO_TRIAL_SUCCESS:
      return {
        ...state,
        trialActive: true
      };

    case UPDATE_DEFENSIVE_DRIVING_ANSWER:
      return {
        ...state,
        defensiveCourseTaken: payload.defensiveCourseTaken,
        loading: true,
        error: false,
        errorMessage: null
      };

    case UPDATE_DEFENSIVE_DRIVING_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case UPDATE_DEFENSIVE_DRIVING_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error.message
      };

    case UPDATE_PRIOR_INSURANCE_LAPSE_REASON:
      return {
        ...state,
        priorInsuranceLapseReason: payload.priorInsuranceLapseReason,
        loading: true,
        error: null,
        errorMessage: null
      };

    case UPDATE_PRIOR_INSURANCE_LAPSE_REASON_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case UPDATE_PRIOR_INSURANCE_LAPSE_REASON_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error
      };

    case REQUEST_PRIOR_INSURANCE_REASONS:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null
      };

    case REQUEST_PRIOR_INSURANCE_REASONS_SUCCESS:
      return {
        ...state,
        priorInsuranceLapseOptions: payload,
        loading: false
      };

    case REQUEST_PRIOR_INSURANCE_REASONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error
      };

    case SET_RIDESHARE_TNC_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null
      };

    case SET_RIDESHARE_TNC_STATUS_SUCCESS:
      return {
        ...state,
        isRideshare: payload.isRideshare,
        loading: false,
        error: null,
        errorMessage: null
      };

    case SET_RIDESHARE_TNC_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error
      };

    case CLEAR_APP_ERRORS:
      return {
        ...state,
        error: false,
        crmGateError: null,
        verificationError: false,
        errorMessage: '',
        form: {
          error: null
        }
      };

    case ASK_MILITARY_SERVICE_VEHICLES:
      return {
        ...state,
        askMilitaryServiceVehicles: true
      };

    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        openModalName: payload
      };

    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        openModalName: null
      };

    case SUBMIT_FORM_ID_SIGNATURE_SUCCESS:
      return {
        ...state,
        isModalOpen: false,
        openModalName: null
      };

    case ASK_DEFENSIVE_DRIVING_IN_DRIVER_EXCLUSION:
      return {
        ...state,
        askDefensiveDrivingInDriverExclusion: true
      };

    default:
      return state;
  }
};

export default appReducer;

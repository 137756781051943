/* eslint-disable default-param-last */
import {
  SAVE_PRIMARY_DRIVER,
  SAVE_PRIMARY_DRIVER_SUCCESS,
  SAVE_PRIMARY_DRIVER_FAILURE,
  EDITING_DRIVER,
  UPDATE_EDITING_DRIVER,
  GET_ASSOCIATED_DRIVERS,
  GET_ASSOCIATED_DRIVERS_SUCCESS,
  UPDATE_ADDITIONAL_DRIVER_INFO,
  COMPLETE_PRIMARY_PERSON_REGISTRATION,
  COMPLETE_ADDED_DRIVER_REGISTRATION,
  UPDATE_PHONE_AND_EMAIL,
  REMOVE_DRIVER,
  REMOVE_DRIVER_SUCCESS,
  REMOVE_DRIVER_FAILURE,
  CLEAR_EDITING_DRIVER,
  UNDO_REMOVE_DRIVER,
  UPDATE_PRIMARY_DRIVER,
  UPDATE_PRIMARY_DRIVER_REQUEST,
  START_ADD_NEW_DRIVER,
  SAVE_ADDITIONAL_DRIVER_INFO,
  SAVE_ADDITIONAL_DRIVER_INFO_SUCCESS,
  SAVE_ADDITIONAL_DRIVER_INFO_FAILURE,
  COMPLETE_ADDING_ADDITIONAL_DRIVER,
  UPDATE_ADDITIONAL_DRIVERS,
  UPDATE_PRIMARY_DRIVER_AND_VERIFY_QUOTE,
  RETRIEVE_USAA_PERSON_INFO_SUCCESS,
  SAVE_EDITING_DRIVER_DL_STATUS,
  CONFIRM_EXCLUDED_DRIVER,
  CONFIRM_EXCLUDED_DRIVER_SUCCESS,
  CONFIRM_EXCLUDED_DRIVER_FAILURE,
  SAVE_AUTOCOMPLETE_ADDRESS
} from '../actions';

const INITIAL_DRIVER_STATE = {
  savingDriver: false,
  editingDriver: {
    type: 'additional',
    driverComplete: false,
    activeBit: true,
    prefill: false,
    activeDriverLicense: null,
    firstName: '',
    lastName: '',
    obfuscatedLastName: '',
    driverId: null,
    dob: null,
    age: null,
    dlNumber: null,
    dlState: null,
    added: false,
    incomplete: true,
    removed: false,
    excluded: false,
    gender: '',
    genderMapping: '',
    maritalStatus: null,
    ageLicensed: 16,
    yearsLicensed: 0,
    askGoodStudentDiscount: false
  },
  items: [],
  editedDrivers: [],
  primaryDriver: {
    activeBit: true,
    addressComplete: false,
    driverComplete: false,
    type: 'primary',
    source: 'Web',
    firstName: '',
    lastName: '',
    givenName: null,
    middleName: null,
    surName: null,
    ageLicensed: 16,
    age: null,
    homeOwner: 'Rent',
    email: '',
    personId: null,
    driverId: null,
    quoteId: null,
    dob: null,
    dateOfBirth: null,
    street: null,
    street2: null,
    streetAddress: null,
    city: null,
    state: null,
    zipCode: null,
    genderMapping: null,
    genderString: null,
    maritalStatusString: null,
    educationLevelString: null,
    safeDriver: null,
    goodStudentDiscount: null,
    dlNumber: null,
    dlState: null,
    softQuote: false,
    createdBy: null,
    askSafeDriving: false,
    askGoodStudentDiscount: false,
    askSeniorDriverImprovement: false,
    askActiveMilitaryPersonnel: false,
    requiresUSAAEligibility: true,
    requiresActiveMilitaryPersonnel: false,
    requiresAirbagVerification: false,
    requiresAllVehiclesOwnedByAMP: false,
    requiresAntitheftDiscount: false,
    requiresCommuteSurcharge: false,
    requiresMedPayOfferForm: false,
    requiresRefusalToWriteNoticeForm: false,
    requiresUmuimOfferForm: false,
    requiresV2CoverageEndpoints: true,
    requiresVehicleAdvancedTechnologyDiscount: false,
    requiresVehicleAssignment: false,
    requiresVehicleOwnershipMonths: false,
    requiresVinEtchingDiscount: false,
    requiresDynamicForms: false,
    requiresDriverExclusion: false
  },
  removedDrivers: 0
};

const driversReducer = (state = INITIAL_DRIVER_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_ADD_NEW_DRIVER:
      return {
        ...state,
        editingDriver: { ...INITIAL_DRIVER_STATE.editingDriver, prefill: false }
      };

    case SAVE_PRIMARY_DRIVER:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver,
          ...payload
        },
        savingDriver: true
      };

    case SAVE_PRIMARY_DRIVER_SUCCESS:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver,
          ...payload
        },
        savingDriver: false
      };

    case SAVE_PRIMARY_DRIVER_FAILURE:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver
        },
        savingDriver: false
      };

    case UPDATE_PRIMARY_DRIVER_AND_VERIFY_QUOTE:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver,
          ...payload
        }
      };

    case UPDATE_PRIMARY_DRIVER:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver,
          ...payload,
          person: {
            ...state.primaryDriver.person,
            ...payload.person
          }
        }
      };

    case UPDATE_PRIMARY_DRIVER_REQUEST:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver,
          ...payload
        }
      };

    case EDITING_DRIVER:
      return {
        ...state,
        editingDriver: {
          ...state.editingDriver,
          ...payload,
          driverComplete: false
        },
        lastEditedDriver: {
          ...payload,
          driverComplete: false,
          lastEdit: 'EDITING_DRIVER'
        },
        primaryDriver: {
          ...state.primaryDriver
        }
      };

    case UPDATE_EDITING_DRIVER:
      return {
        ...state,
        editingDriver: {
          ...state.editingDriver,
          ...payload
        },
        lastEditedDriver: {
          ...state.lastEditedDriver,
          lastEdit: 'UPDATE_EDITING_DRIVER'
        }
      };

    case SAVE_EDITING_DRIVER_DL_STATUS:
      return {
        ...state,
        editingDriver: {
          ...state.editingDriver,
          activeDriverLicense: payload.activeDriverLicense === 'Yes'
        }
      };

    case SAVE_ADDITIONAL_DRIVER_INFO:
      return {
        ...state,
        editingDriver: {
          ...state.editingDriver
        },
        savingDriver: true
      };

    case SAVE_ADDITIONAL_DRIVER_INFO_SUCCESS:
      return {
        ...state,
        editingDriver: {
          ...state.editingDriver,
          ...payload,
          dob: payload.dob || '',
          obfuscatedLastName: payload.lastName
        },
        savingDriver: false
      };

    case SAVE_ADDITIONAL_DRIVER_INFO_FAILURE:
      return {
        ...state,
        savingDriver: false
      };

    case UPDATE_ADDITIONAL_DRIVER_INFO:
      return {
        ...state,
        editingDriver: {
          ...state.editingDriver,
          ...payload
        },
        savingDriver: true
      };

    case UPDATE_ADDITIONAL_DRIVERS:
      return {
        ...state,
        items: state.items.map(driver =>
          payload[driver.driverId]
            ? {
                ...driver,
                ...payload[driver.driverId]
              }
            : driver
        )
      };

    case CLEAR_EDITING_DRIVER:
      return {
        ...state,
        editingDriver: { ...INITIAL_DRIVER_STATE.editingDriver },
        savingDriver: false
      };

    case UPDATE_PHONE_AND_EMAIL:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver,
          email: payload.email
        }
      };

    case GET_ASSOCIATED_DRIVERS:
      return {
        ...state,
        items: []
      };

    case GET_ASSOCIATED_DRIVERS_SUCCESS:
      return {
        ...state,
        items: [...payload]
      };

    case COMPLETE_PRIMARY_PERSON_REGISTRATION:
      return {
        ...state,
        primaryDriver: {
          ...payload
        },
        primaryDriverComplete: true
      };

    case COMPLETE_ADDED_DRIVER_REGISTRATION:
      return {
        ...state,
        items: [...payload.items],
        editingDriver: { ...INITIAL_DRIVER_STATE.editingDriver },
        savingDriver: false
      };

    // todo: remove last edited driver?
    case REMOVE_DRIVER:
      return {
        ...state,
        lastEditedDriver: {
          ...payload,
          lastEdit: 'REMOVE_DRIVER'
        },
        savingDriver: true
      };

    case REMOVE_DRIVER_SUCCESS:
      return {
        ...state,
        items: state.items.map(driver =>
          driver.driverId === payload
            ? {
                ...driver,
                activeBit: false,
                driverComplete: true
              }
            : driver
        ),
        lastEditedDriver: {
          ...state.lastEditedDriver,
          activeBit: false,
          driverComplete: true
        },
        savingDriver: false,
        removedDrivers: state.removedDrivers + 1
      };

    case REMOVE_DRIVER_FAILURE:
      return {
        ...state,
        savingDriver: false
      };

    case UNDO_REMOVE_DRIVER:
      return {
        ...state,
        items: state.items.map(item =>
          item.driverId !== payload.driverId
            ? item
            : {
                ...item,
                driverComplete: false,
                removed: false,
                incomplete: true,
                activeBit: true
              }
        ),
        lastEditedDriver: {
          ...state.lastEditedDriver,
          activeBit: true,
          driverComplete: false,
          removed: false,
          incomplete: true,
          lastEdit: 'UNDO_REMOVE_DRIVER'
        },
        removedDrivers:
          state.removedDrivers === 0
            ? state.removedDrivers
            : state.removedDrivers - 1
      };

    case COMPLETE_ADDING_ADDITIONAL_DRIVER: {
      const completedDriver = {
        ...state.editingDriver,
        edited: true,
        added: true,
        incomplete: false,
        ...payload
      };

      const driverExists = state.items.reduce((acc, driver) => {
        if (driver.driverId === completedDriver.driverId) {
          // eslint-disable-next-line no-param-reassign
          acc = true;
        }

        return acc;
      }, false);

      const drivers = driverExists
        ? state.items.map(driver => {
            if (driver.driverId === completedDriver.driverId) {
              return {
                ...driver,
                ...completedDriver
              };
            }

            return driver;
          })
        : state.items
            .slice()
            .concat({ ...completedDriver })
            .reverse();

      return {
        ...state,
        items: [...drivers],
        lastEditedDriver: {
          ...completedDriver,
          lastEdit: 'EDITING_DRIVER'
        },
        editedDrivers: state.editedDrivers.includes(completedDriver.driverId)
          ? state.editedDrivers
          : [...state.editedDrivers, completedDriver.driverId],
        editingDriver: { ...INITIAL_DRIVER_STATE.editingDriver },
        savingDriver: false
      };
    }

    case RETRIEVE_USAA_PERSON_INFO_SUCCESS:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver,
          ...payload,
          isUSAAMember: true,
          requiresUSAAEligibility: false
        }
      };

    case CONFIRM_EXCLUDED_DRIVER:
      return {
        ...state,
        editingDriver: {
          ...state.editingDriver
        },
        savingDriver: true
      };

    case CONFIRM_EXCLUDED_DRIVER_SUCCESS:
      return {
        ...state,
        editingDriver: {
          ...state.editingDriver,
          ...payload
        },
        savingDriver: false
      };

    case CONFIRM_EXCLUDED_DRIVER_FAILURE:
      return {
        ...state,
        savingDriver: false
      };

    case SAVE_AUTOCOMPLETE_ADDRESS:
      return {
        ...state,
        primaryDriver: {
          ...state.primaryDriver,
          ...payload
        }
      };

    default:
      return state;
  }
};

export default driversReducer;

// shared pages
import NamePage from './shared/name';
import DateOfBirth from './shared/dateOfBirth';
import AgeLicensedPage from './shared/ageLicensed';
import GenderPage from './shared/gender';
import MaritalStatusPage from './shared/maritalStatus';
import DriverLicensePage from './shared/driverLicense';
import DefensiveDrivingCourse from './shared/defensiveDrivingCourse';
import MilitaryServiceDriverPage from './shared/militaryServiceDriver';
import GoodStudentDiscount from './shared/goodStudentDiscount';
// startQuote pages
import AddressPage from './startQuote/address';
import AddressEntryPage from './startQuote/addressEntry';
import EmailPage from './startQuote/email';
import HomeOwnershipPage from './startQuote/homeOwnership';
import EducationPage from './startQuote/education';
import ConfirmPrimaryDriverInfoPage from './startQuote/confirmPrimaryDriverInfo';
import PartnerLandingPage from './startQuote/PartnerLandingPage';
import ReferralPage from './startQuote/referral';
import DriverImprovementPage from './startQuote/driverImprovementDiscount';
import PrimaryEligibilityPage from './startQuote/primaryEligibilityPage';
import SecondaryEligibilityPage from './startQuote/secondaryEligibilityPage';
import QuoteIntegration from './startQuote/QuoteIntegration';
// addDrivers pages
import DriversListPage from './addDrivers/driversList';
import ConfirmDriverPage from './addDrivers/confirmDriver';
import PriorInsuranceLapse from './addDrivers/PriorInsuranceLapse';
import VerifyActiveDriverLicense from './addDrivers/VerifyActiveDriverLicense';
import ConfirmDriverExclusion from './addDrivers/ConfirmDriverExclusion';
// addVehicles pages
import VehiclesListPage from './addVehicles/vehiclesList';
import VehicleOwnershipPage from './addVehicles/vehicleOwnership';
import VehicleOwnershipLengthPage from './addVehicles/vehicleOwnershipLength';
import EmergencyBrakingPage from './addVehicles/emergencyBraking';
import NewVehiclePage from './addVehicles/newVehicle';
import RideSharePage from './addVehicles/rideShare';
import MilitaryServiceVehiclePage from './addVehicles/militaryServiceVehicles';
import VinPage from './addVehicles/vin';
import AntiTheftDevicePage from './addVehicles/antiTheftDevice';
import VinEtchingPage from './addVehicles/vinEtching';
import CommuteSurcharge from './addVehicles/commuteSurcharge';
import PrimaryCarAssignment from './addVehicles/primaryCarAssignment';
import CarAssignmentFollowup from './addVehicles/carAssignmentFollowup';
import Airbags from './addVehicles/airbags';
// drivingBehavior
import Mileage from './drivingBehavior/mileage';
import Persona from './drivingBehavior/persona';
// purchase pages
import VerificationIssuePage from './purchase/verificationIssue';
import VinConfirmPage from './purchase/vinConfirm';
import EffectiveDatePage from './purchase/effectiveDate';
import PaymentScheduleV2 from './purchase/paymentScheduleV2';
import ReviewPage from './purchase/review';
import SignatureStart from './purchase/start';
import InsuranceApplicationPage from './purchase/signInsuranceApplication';
import UmbiSelectionFormPage from './purchase/signUmbiForm';
import UimbiSelectionFormPage from './purchase/signUimbiForm';
import UmSelectionFormPage from './purchase/signUmForm';
import DynamicPreBindForm from './purchase/dynamicPreBindForm';
import PipNoticeFormPage from './purchase/signPipNoticeForm';
import TortOptionsFormPage from './purchase/tortOptionsForm';
import MedPayForm from './purchase/MedPayForm';
import SignUmuimOfferForm from './purchase/SignUmuimOfferForm';
import MembershipApplicationPage from './purchase/signMembershipApplication';
import UmbrellaPolicyAcknowledgement from './purchase/UmbrellaPolicyAcknowledgement';
import UmbrellaPolicy from './purchase/UmbrellaPolicy';
import PaymentInfoPage from './purchase/paymentInfo';
import ThankYouPage from './purchase/thankYou';
// account pages
import SignInPage from './account/signIn';
import SignUpPage from './account/signUp';
import ConfirmEmailPage from './account/confirmEmail';
import CodeEntryPage from './account/codeEntry';
import ResetPasswordPage from './account/resetPassword';
import DownloadPage from './account/download';
import NeedHelpPage from './account/needHelp';
// dnq pages
import SoftStopPage from './dnq/softStop';
import AgencyDoNotQuote from './dnq/agencyDoNotQuote';
import DeclinedDoNotQuote from './dnq/declinedDoNotQuote';
// quote pages
import QuoteRetrievalPage from './quote/retrieval';
// driverExclusion Pages
import HouseholdMember from './driverExclusion/HouseholdMember';
import AnyCars from './driverExclusion/AnyCars';
import ActiveDriverLicense from './driverExclusion/ActiveDriverLicense';
import ConfirmCovered from './driverExclusion/ConfirmCovered';
import Confirmation from './driverExclusion/Confirmation';
import ConfirmAdditionalDriver from './driverExclusion/ConfirmAdditionalDriver';
import ConfirmDrivingCourses from './driverExclusion/ConfirmDrivingCourses';

const dnqPages = {
  agency: AgencyDoNotQuote,
  detailed: DeclinedDoNotQuote,
  usaa: DeclinedDoNotQuote,
  'call-to-complete': SoftStopPage
};

export default {
  'lets-go': PartnerLandingPage,
  referral: ReferralPage,
  welcome: QuoteIntegration, // USAA Quote Flow Integration Landing Page
  // quote flow
  name: NamePage,
  'date-of-birth': DateOfBirth,
  address: AddressPage,
  email: EmailPage,
  'confirm-primary-driver-info': ConfirmPrimaryDriverInfoPage,
  'home-ownership': HomeOwnershipPage,
  'address-entry': AddressEntryPage,
  'verify-active-driver-license': VerifyActiveDriverLicense,
  'confirm-excluded': ConfirmDriverExclusion,
  'driver-license': DriverLicensePage,
  'age-licensed': AgeLicensedPage,
  gender: GenderPage,
  'marital-status': MaritalStatusPage,
  education: EducationPage,
  'drivers-list': DriversListPage,
  'confirm-driver': ConfirmDriverPage,
  'defensive-driving-course': DefensiveDrivingCourse,
  'safe-driving': DefensiveDrivingCourse,
  'driver-improvement-discount': DriverImprovementPage,
  'active-military-service': MilitaryServiceDriverPage,
  'usaa-membership': PrimaryEligibilityPage,
  'usaa-eligibility': SecondaryEligibilityPage,
  'prior-insurance': PriorInsuranceLapse,
  'good-student-discount': GoodStudentDiscount,
  // vehicle
  'vehicles-list': VehiclesListPage,
  'vehicle-ownership': VehicleOwnershipPage,
  'vehicle-ownership-length': VehicleOwnershipLengthPage,
  'emergency-braking': EmergencyBrakingPage,
  'add-new-vehicle': NewVehiclePage,
  'anti-theft': AntiTheftDevicePage,
  'vin-etching': VinEtchingPage,
  commute: CommuteSurcharge,
  vin: VinPage,
  'ride-share': RideSharePage,
  'military-service': MilitaryServiceVehiclePage,
  'car-assignment': PrimaryCarAssignment,
  'car-assignment-followup': CarAssignmentFollowup,
  airbags: Airbags,
  mileage: Mileage,
  'select-persona': Persona,

  // pre-purchase flow
  'verify-drivers-license': DriverLicensePage,
  'dl-verification-issue': VerificationIssuePage,

  // driver exclusion
  'household-member': HouseholdMember,
  'any-cars': AnyCars,
  'active-driver-license': ActiveDriverLicense,
  'confirm-covered': ConfirmCovered,
  'confirm-additional-driver': ConfirmAdditionalDriver,
  confirmation: Confirmation,
  'confirm-defensive-driving-course': ConfirmDrivingCourses,
  'confirm-safe-driving': ConfirmDrivingCourses,

  // purchase flow
  'vin-confirm': VinConfirmPage,

  // purchase flow
  'effective-date': EffectiveDatePage,
  'payment-schedule': PaymentScheduleV2,
  'review-and-pay': ReviewPage,
  'signature-start': SignatureStart,
  'signature-start-nv': SignatureStart,
  'sign-insurance-application': InsuranceApplicationPage,
  'sign-umbi-selection': UmbiSelectionFormPage,
  'sign-uimbi-selection': UimbiSelectionFormPage,
  'sign-um-selection': UmSelectionFormPage,
  'sign-pip-notice': PipNoticeFormPage,
  'notice-to-insured': TortOptionsFormPage,
  'sign-membership-application': MembershipApplicationPage,
  'sign-form': DynamicPreBindForm,
  'sign-nv-medpay-form': MedPayForm,
  'sign-nv-um-form': SignUmuimOfferForm,
  'umbrella-policy-acknowledgement': UmbrellaPolicyAcknowledgement,
  'umbrella-policy': UmbrellaPolicy,
  'payment-info': PaymentInfoPage,
  'thank-you': ThankYouPage,
  'need-help': NeedHelpPage,

  // account
  'sign-in': SignInPage,
  'setup-account': SignUpPage,
  'setup-trial': SignUpPage,
  'confirm-email': ConfirmEmailPage,
  'code-entry': CodeEntryPage,
  'create-password': ResetPasswordPage,
  'download-app': DownloadPage,

  // quote
  'retrieve-your-quote': QuoteRetrievalPage,

  // dnq
  ...dnqPages
};

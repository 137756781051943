import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';
import Noblr from '@noblr-lab/react-app-services';
import { Cookies } from 'react-cookie';
import {
  put,
  select,
  all,
  delay,
  call,
  actionChannel,
  takeEvery,
  putResolve
} from 'redux-saga/effects';
import {
  getUrlParameter,
  getCookie,
  createPayload,
  configureCookieName,
  stripDomainFromEmail
} from 'utilities';
import { roundMileageValue } from 'utilities/coverage';
import {
  SALES_FORCE_GATES,
  SECURE_COOKIE_HEADER_CONFIG,
  EXPIRES_COOKIE_HEADER_CONFIG
} from '../../constants';

const cookies = new Cookies();

/**
 * Updates driver or vehicle
 * @param {Object} action
 */
export function* editingItemWatcher({ payload }) {
  const {
    app: { usaaPersonInfoVerified },
    drivers: { editingDriver }
  } = yield select();

  if (editingDriver.driverId && payload && payload.driverId) {
    yield putResolve({
      type: 'CLEAR_EDITING_DRIVER'
    });
  }

  // if payload has driver license, update driver
  if (Object.prototype.hasOwnProperty.call(payload, 'driverId')) {
    const driverPayload = { ...payload, edited: true };

    if (
      !usaaPersonInfoVerified &&
      driverPayload.prefill &&
      (!driverPayload.added || !driverPayload.edited)
    ) {
      driverPayload.lastName = '';
    }

    if (driverPayload.type === 'excluded') {
      driverPayload.type = 'additional';
      driverPayload.driverComplete = false;
      driverPayload.excluded = false;
      driverPayload.incomplete = true;
    }
    yield putResolve({
      type: 'UPDATE_EDITING_DRIVER',
      payload: { ...driverPayload }
    });
  } else {
    yield putResolve({
      type: 'UPDATE_EDITING_VEHICLE',
      payload
    });

    yield putResolve({
      type: 'UPDATE_VEHICLE',
      payload
    });
  }
}

/**
 * Removes driver or vehicle
 * @param {Object} action
 */
export function* removeItemWatcher(action) {
  const { payload } = action;

  if (payload.listType === 'drivers') {
    yield put({
      type: 'REMOVE_DRIVER',
      payload: payload.item
    });
  } else if (payload.listType === 'vehicles') {
    yield put({
      type: 'REMOVE_VEHICLE_REQUEST',
      payload: payload.item
    });
  }
}

/**
 * Readd a removed driver or vehicle
 * @param {Object} action
 */
export function* undoRemoveWatcher(action) {
  const { payload } = action;

  if (payload.listType === 'drivers') {
    yield put({
      type: 'UNDO_REMOVE_DRIVER',
      payload: payload.item
    });
  } else if (payload.listType === 'vehicles') {
    yield put({
      type: 'UNDO_REMOVE_VEHICLE',
      payload: payload.item
    });
  }
}

/**
 * Fetch prefill data for primary driver
 */
export function* prefillRequestWatcher() {
  const {
    app: { error, loading, hasPartnerData, leadId = null },
    drivers: { primaryDriver: existingPrimaryDriver }
  } = yield select();

  // if there is an error, break out before calling prefill
  if (error && error.critical) {
    return;
  }

  if (!loading) {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: true
      }
    });
  }

  try {
    const response = yield call(Noblr.prefill.fetchPrefill);
    const {
      drivers,
      vehicles,
      softQuote,
      coverages,
      numberOfDaysWithoutInsurance = null
    } = response;

    // softQuote responses don't have drivers or vehicles, just set softQuote and break out
    if (softQuote) {
      yield put({
        type: 'REQUEST_PREFILL_SUCCESS',
        payload: { requiresDLVerification: false }
      });

      yield put({
        type: 'UPDATE_PRIMARY_DRIVER',
        payload: { softQuote }
      });

      return;
    }

    const prefillVehicles = vehicles
      // add prefill boolean to returned vehicles
      .map(vehicle => ({
        ...vehicle,
        prefill: true
      }))
      // sort vehicles descending by year
      .sort((a, b) => parseInt(b.year, 10) - parseInt(a.year, 10));

    const prefillDrivers = drivers
      .filter(driver => driver.type !== 'primary')
      // add prefill boolean to returned additional drivers
      .map(driver => ({
        ...driver,
        obfuscatedLastName: driver.lastName,
        lastName: '',
        prefill: true
      }));

    // extract primary driver, should only be one
    const primaryDriver = drivers.find(driver => driver.type === 'primary');
    let primaryDriverPayload = {};

    // require DL verification only if dl number is sent from prefill
    // TODO: Use Boolean Flag to determine whether DL verification page is displayed when the changes have been implemented in the BE
    const requiresDLVerification = !!primaryDriver && !!primaryDriver.dlNumber;
    const askPriorInsuranceLapseReason =
      (existingPrimaryDriver.state === 'GA' ||
        existingPrimaryDriver.state === 'IN') &&
      numberOfDaysWithoutInsurance !== null &&
      numberOfDaysWithoutInsurance > 0;

    /*
      store existing primary driver DOB in variable in case primary driver arrived on marketing landing page via leadcloud
      */
    const existingPrimaryDriverDOB =
      existingPrimaryDriver.dob || existingPrimaryDriver.dateOfBirth;

    /*
      if primary driver arrived on leadcloud marketing landing page, use the dob from leadcloud instead of prefill information to prevent 500 error on leadcloud marketing landing page if PM revisits marketing landing page
      */
    const primaryDriverDOB =
      hasPartnerData && leadId ? existingPrimaryDriverDOB : primaryDriver.dob;

    if (primaryDriver) {
      primaryDriverPayload = {
        ...primaryDriverPayload,
        ...primaryDriver,
        prefill: primaryDriver.dlNumber !== null,
        dob: primaryDriverDOB,
        dateOfBirth: primaryDriverDOB,
        // map prefill variables to our variables
        maritalStatus: primaryDriver.maritalStatusString,
        education: primaryDriver.educationLevelString,
        ageLicensed:
          primaryDriver.ageLicensed || existingPrimaryDriver.ageLicensed,
        homeOwner: primaryDriver.homeOwner || existingPrimaryDriver.homeOwner
      };
    }

    // when we get a response, trigger actions to update drivers, vehicles, and coverages
    if (response) {
      yield all([
        putResolve({
          type: 'UPDATE_PRIMARY_DRIVER',
          payload: primaryDriverPayload
        }),
        putResolve({
          type: 'GET_ASSOCIATED_DRIVERS',
          payload: prefillDrivers
        }),
        putResolve({
          type: 'GET_ASSOCIATED_VEHICLES',
          payload: prefillVehicles
        }),
        putResolve({
          type: 'GET_PREFILL_COVERAGES',
          payload: coverages
        }),
        putResolve({
          type: 'REQUEST_PREFILL_SUCCESS',
          payload: {
            veriskHit: requiresDLVerification,
            requiresDLVerification,
            askPriorInsuranceLapseReason,
            numberOfDaysWithoutInsurance
          }
        })
      ]);
    }
  } catch (error) {
    if (error && error.status) {
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
      yield put({
        type: 'PREFILL_REQUEST_FAILURE',
        error
      });
    }
  } finally {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: false
      }
    });
  }
}

/**
 * Clear async error after briefly displaying
 * todo: remove this?
 */
export function* displayAsyncError() {
  yield delay(1000);

  yield put({
    type: 'CLEAR_ASYNC_ERRORS'
  });
}
/**
 * Determine whether to display error inline or redirect to error page
 * Sets criticalError boolean based on error status code
 * @param {Object} action
 */
export function* setErrorLevel(action) {
  const {
    error: {
      status,
      policyExists,
      trialExists,
      message,
      DNQ,
      critical,
      mediaAlpha,
      showMessageDetail,
      messageDetail,
      mediaAlphaPrompt,
      header
    }
  } = action;

  const errorHeader = policyExists === 'true' && 'Hi There! Need Help?';
  const stateWithdrawalDNQ = DNQ === 'STATE_WITHDRAWAL';
  const agencyDnq =
    DNQ === 'STATE_NOT_SUPPORTED' ||
    DNQ === 'DL_STATE_NOT_SUPPORTED' ||
    DNQ === 'RIDESHARE_NOT_OK';

  const usaaDnq = DNQ === 'USAA_MEMBERSHIP_INELIGIBLE';

  let errorMessage =
    status === 400 || status === 403 || status === 422 ? message : null;

  const criticalError =
    critical ||
    status === 401 ||
    status === 422 ||
    status === 424 ||
    status === 500 ||
    status === 503 ||
    (policyExists === 'true' && trialExists === 'false');

  const sessionCookie = getCookie(configureCookieName('session'));

  // if jwt has expired and we don't have a session cookie, redirect user to start screen
  if (status === 401 && !sessionCookie) {
    yield put({ type: 'REDIRECT', payload: { url: '/start-quote/name' } });

    return;
  }

  if (policyExists === 'true') {
    errorMessage = `We see that you have a Noblr car insurance policy already. Please log into your app for information about your account. If you need assistance or would like to get pricing information, please give us a call at`;
  }

  yield put({
    type: 'SET_ERROR_LEVEL',
    error: {
      ...action.error,
      critical: criticalError,
      errorHeader,
      message: errorMessage,
      policyExists,
      trialExists
    }
  });

  // if there is a DNQ, set redirect url based on DNQ type and redirect
  if (status === 422) {
    // if mediaAlpha is true
    if (mediaAlpha === 'true') {
      // // update app with media alpha details
      FullStory.event('DNQ Triggered', {
        DNQ_str: DNQ
      });

      yield putResolve({
        type: 'UPDATE_APP',
        payload: {
          doNotQuote: {
            DNQ,
            header,
            message,
            showMessageDetail,
            messageDetail,
            mediaAlphaPrompt
          }
        }
      });

      // then redirect to '/dnq/usaa' if DNQ = USAA_MEMBERSHIP_INELIGIBLE else if agencyDnq to '/dnq/agency'
      if (usaaDnq) {
        yield put({
          type: 'REDIRECT',
          payload: {
            url: '/dnq/usaa'
          }
        });
      } else if (agencyDnq) {
        yield put({
          type: 'REDIRECT',
          payload: {
            url: '/dnq/agency'
          }
        });
      } else if ((!agencyDnq && !usaaDnq) || stateWithdrawalDNQ) {
        yield put({
          type: 'REDIRECT',
          payload: {
            url: '/dnq/detailed'
          }
        });
      }
    } else {
      // redirect to /dnq when we don't have dnq details to display from media alpha
      yield put({ type: 'REDIRECT', payload: { url: '/dnq' } });
    }
  }
}

/**
 * Clear async Status
 * todo: we can definitely remove this...
 */
export function* clearAsyncStatus() {
  yield put({
    type: 'CLEAR_ASYNC_STATUS'
  });
}

/**
 * Retrieve user's jwt
 * Fetch quote data using retrieved jwt
 * Determines where to drop user in the flow
 *
 * @param {Object} action
 */
export function* retrieveQuoteSubroutine({ payload }) {
  yield put({
    type: 'CLEAR_APP_ERRORS'
  });

  yield put({
    type: 'TOGGLE_LOADER',
    payload: { toShow: true }
  });

  // if retrieve quote includes query param campaign=trial, we will pass this boolean to review quote url
  const showTrial =
    getUrlParameter(window.location.href, 'campaign') === 'trial';

  if (showTrial) {
    //  Manually set tags in current scope for Sentry
    Sentry.withScope(scope => {
      scope.setTag('active_trial', showTrial);
    });
  }

  let requestPayload = {};

  // Enc string means trial user is retrieving quote from email link
  if (payload.enc && payload.enc.length) {
    // email and dob are optional but this accounts for persisted data
    // extract rest of payload
    const { primaryEmail, primaryDOB, ...rest } = payload;
    const email = primaryEmail || '';
    const dob = primaryDOB || '';

    requestPayload = createPayload({
      ...rest,
      email,
      dob
    });
  } else {
    // todo: use dob in payload but make sure it doesn't need to be required
    // TODO confirm whether we can we remove this comment
    requestPayload = createPayload({
      ...payload,
      dob: payload.dateOfBirth || ''
    });
  }

  try {
    window.dataLayer = window.dataLayer || [];

    const { dataLayer } = window;
    // retrieve quote endpoint doesn't return all quote details
    // instead we get back jwt, personId, quoteId and set them as cookies
    const {
      jwt,
      personId,
      quoteId,
      quoteComplete,
      email = ''
    } = yield call(Noblr.quote.retrieveQuote, requestPayload);

    dataLayer.push({
      quoteId
    });

    cookies.set(
      configureCookieName('session'),
      jwt,
      SECURE_COOKIE_HEADER_CONFIG
    );
    cookies.set(
      configureCookieName('personId'),
      personId,
      SECURE_COOKIE_HEADER_CONFIG
    );
    cookies.set(
      configureCookieName('quoteId'),
      quoteId,
      SECURE_COOKIE_HEADER_CONFIG
    );

    // Remove domain from email for FullStory
    const primaryEmailWithoutDomain = stripDomainFromEmail(email);

    FullStory.identify(personId, {
      email: primaryEmailWithoutDomain,
      personId_str: personId,
      quoteId_str: quoteId
    });

    // Set quoteId user variables in FS before API call
    FullStory.setUserVars({
      quoteId_str: quoteId,
      quoteSource_str: 'quote_retrieval',
      quoteComplete_bool: quoteComplete
    });

    Sentry.setUser({ id: personId });
    Sentry.setTag('quote_id', quoteId);
    Sentry.setTag('quote_retrieval', true);
    Sentry.setTag('quote_complete', quoteComplete);

    // update primary driver with the request payload and email returned in case one wasn't passed in
    yield put({
      type: 'UPDATE_PRIMARY_DRIVER',
      payload: { ...requestPayload, personId, quoteId, email }
    });

    // once we have jwt as a session cookie, we can make private api calls to get quote data
    // putResolve is blocking
    yield putResolve({
      type: 'FETCH_QUOTE_DATA',
      payload: {
        quoteComplete,
        showTrial
      }
    });
    // call this after everything so loader shows until all puts are complete
    yield put({
      type: 'RETRIEVE_QUOTE_SUCCESS'
    });
    // Keep loader to prevent re-render of retrieval
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: true }
    });
  } catch (error) {
    // Set fingerprint and capture exception in Sentry
    Sentry.withScope(scope => {
      scope.setFingerprint([window.location.pathname]);
    });

    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });

    const trialActive =
      error.data && error.data.trialExists && error.data.trialExists === 'true';

    const policyActive =
      error.data &&
      error.data.policyExists &&
      error.data.policyExists === 'true';

    // redirect user to sign in if they already have a policy
    if (error.status === 409) {
      // Set Sentry Tag
      Sentry.setTag('active_trial', trialActive);
      Sentry.setTag('policy_exists', policyActive);

      yield putResolve({
        type: 'UPDATE_APP',
        payload: {
          trialActive,
          policyActive
        }
      });

      yield putResolve({
        type: 'UPDATE_AUTH_STATUS',
        payload: { trialActive, isTrial: trialActive, policyActive }
      });

      yield put({
        type: 'REDIRECT',
        payload: {
          url: '/account/sign-in'
        }
      });

      // no quote found
    } else if (error.status === 404) {
      yield put({
        type: 'REDIRECT',
        payload: {
          url: '/quote/not-found'
        }
      });
    } else if (error.status === 422) {
      yield put({
        type: 'SET_ERROR',
        error: {
          status: error.status,
          message: error.data.message,
          ...error.data
        }
      });
    } else {
      // set critical error if quote retrieval fails for any other reason
      yield put({
        type: 'SET_ERROR',
        error: { critical: true, ...error.data }
      });
      yield put({
        type: 'RETRIEVE_QUOTE_FAILURE',
        error: { message: error.data.message, status: error.status }
      });
    }
    yield put({
      type: 'RETRIEVE_QUOTE_FAILURE',
      error: { message: error.data.message, status: error.status }
    });
  }
}

export function* retrieveUSAAPersonInfoSubroutine({ payload }) {
  const { quoteId } = payload;

  // Set quoteId tag in current scope in Sentry before API call
  Sentry.setTag('quoteId', quoteId);

  // Set quoteId user variables in FS before API call
  FullStory.setUserVars({
    quoteId_str: quoteId,
    quoteSource_str: 'usaa_quote_integration'
  });

  try {
    const { jwt, personId, firstName, lastName } = yield call(
      Noblr.quote.retrieveUSAAPersonInfo,
      payload
    );

    const { quoteId } = payload;

    yield put({
      type: 'RETRIEVE_USAA_PERSON_INFO_SUCCESS',
      payload: {
        firstName,
        lastName,
        quoteId,
        personId
      }
    });

    // Identify User in FS by passing personId as UID
    FullStory.identify(personId, {
      personId_str: personId
    });

    //  Manually set tags in current scope in Sentry
    Sentry.setUser({
      id: personId
    });

    window.dataLayer = window.dataLayer || [];

    const { dataLayer } = window;

    dataLayer.push({ quoteId });

    // Set "session", "personId" and "quoteId" cookies
    cookies.set(
      configureCookieName('session'),
      jwt,
      SECURE_COOKIE_HEADER_CONFIG
    );
    cookies.set(
      configureCookieName('personId'),
      personId,
      SECURE_COOKIE_HEADER_CONFIG
    );
    cookies.set(
      configureCookieName('quoteId'),
      quoteId,
      SECURE_COOKIE_HEADER_CONFIG
    );
  } catch (error) {
    FullStory.setUserVars({
      usaaIntegrationError_bool: true,
      quoteSource_str: 'usaa_quote_integration'
    });
    Sentry.setTag('usaaQuoteIntegrationError', true);

    if (error && error.status && error.data && error.data.message) {
      yield put({
        type: 'RETRIEVE_USAA_PERSON_INFO_FAILURE',
        error: { message: error.data.message, status: error.status }
      });
    }

    yield put({
      type: 'RETRIEVE_USAA_PERSON_INFO_FAILURE',
      error: { message: 'Error retrieving quote', status: null }
    });
    yield put({ type: 'REDIRECT', payload: { url: '/quote/not-found' } });
  } finally {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });
  }
}

export function* verifyUSAAPersonAndRetrieveQuoteSubroutine({
  payload,
  successRoute
}) {
  yield put({
    type: 'CLEAR_APP_ERRORS'
  });

  yield put({
    type: 'TOGGLE_LOADER',
    payload: { toShow: true }
  });

  try {
    const {
      email,
      quoteId,
      personId,
      // quoteComplete, //  TODO
      drivers,
      removedDrivers,
      excludedDrivers,
      vehicles,
      // quoteCoverages,  //  TODO: Uncomment for USAA Quote Flow
      mileage,
      personaCode
    } = yield call(Noblr.quote.verifyUSAAPersonInfoAndRetrieveQuote, payload);

    // immediately dispatch success action
    yield put({
      type: 'VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_SUCCESS'
    });

    const driversToExclude = excludedDrivers || [];
    const driversToRemove = removedDrivers || [];

    const activeAdditionalDrivers = drivers.filter(
      driver => driver.type === 'additional'
    );

    const [primaryDriver] = drivers
      .filter(driver => driver.type === 'primary')
      .map(driver => {
        const updatedDriver = {
          ...driver,
          prefillComplete: true, // USAA provides prefill
          addressComplete: true, // USAA provides address
          dateOfBirth: payload.dob // manually set dateOfBirth
        };

        /* manually set email  because response
        does not send it inside primary driver Object */
        if (email) {
          updatedDriver.email = email;

          // Remove domain from email for FullStory
          const primaryEmailWithoutDomain = stripDomainFromEmail(email);

          FullStory.identify(personId, {
            email: primaryEmailWithoutDomain,
            personId_str: personId,
            quoteId_str: quoteId
          });
        }

        return updatedDriver;
      });

    // call this after everything so loader shows until all puts are complete
    yield all([
      putResolve({
        type: 'CONFIRMED_PII',
        payload: {
          prefillComplete: true,
          confirmedPii: {
            dob: true,
            street: true
          }
        }
      }),
      putResolve({
        type: 'GET_ALL_DRIVERS_SUCCESS',
        payload: {
          drivers: [
            ...activeAdditionalDrivers,
            ...driversToRemove,
            ...driversToExclude,
            primaryDriver
          ],
          quoteId,
          personId,
          primaryDriverEmail: email
        }
      }),
      putResolve({
        type: 'GET_ALL_VEHICLES_SUCCESS',
        payload: { vehicles }
      }),
      putResolve({
        type: 'SAVE_MILEAGE_SUCCESS',
        payload: {
          mileage: mileage || 15,
          savedMileage: !!mileage
        }
      }),
      putResolve({
        type: 'SAVE_PERSONA_SUCCESS',
        payload: { personaCode }
      })
    ]);
    /* if USAA sends email address,  redirect to '/start-quote/confirm-primary-driver-info otherwise, redirect to email page */
    yield put({
      type: 'REDIRECT',
      payload: {
        url: email && email.length ? successRoute : '/start-quote/email'
      }
    });
  } catch (error) {
    yield put({
      type: 'VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_FAILURE',
      error: { message: error.message, status: error.status }
    });

    if (error && error.status === 422) {
      yield put({
        type: 'RESET_QUOTE'
      });
      yield put({
        type: 'REDIRECT',
        payload: {
          url: '/quote/not-found'
        }
      });
    }
  } finally {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });
  }
}

/**
 * fetch drivers, vehicles, coverages, product data
 * for logged in users or with jwt from quote retrieval
 */
export function* fetchQuoteDataSubroutine({
  payload: { quoteComplete, showTrial }
}) {
  const {
    drivers: {
      primaryDriver: { personId }
    }
  } = yield select();

  try {
    const [drivers, vehicles, coverage, { mileage }, { personaCode }] =
      yield all([
        call(Noblr.driver.getDrivers),
        call(Noblr.vehicle.getAllVehicles),
        call(Noblr.coverage.getCoverage),
        call(Noblr.coverage.getMileage),
        call(Noblr.coverage.getPersona)
      ]);

    const {
      type, // default coverage package
      ...retrievedCoverage
    } = coverage;

    const currentPackage = type;

    yield put({
      type: 'SET_CURRENT_PACKAGE',
      payload: currentPackage
    });

    const [primaryDriver] = drivers.filter(({ type }) => type === 'primary');
    const {
      state,
      driverId,
      quoteId,
      email,
      driverComplete: primaryDriverComplete
    } = primaryDriver;

    const primaryDriverEmailUsername = stripDomainFromEmail(email);

    if (personId && email) {
      FullStory.identify(personId, {
        email: primaryDriverEmailUsername,
        garagingState_str: state,
        quoteId_str: quoteId,
        driverId_str: driverId
      });
    }

    // if we don't have product data for packages, call product data
    if (!coverage?.coveragePackages || !coverage?.ratePlanStateCode) {
      yield putResolve({
        type: 'GET_PRODUCT_DATA'
      });
    }

    const roundedMileage = roundMileageValue(mileage);

    /* if driver and quote are complete,
    update quote flow values that possibly were reset before retrieving quote */
    if (quoteComplete && primaryDriverComplete) {
      yield put({
        type: 'CONFIRMED_PII',
        payload: {
          isUSAAMember: true,
          registeredQuote: true,
          prefillComplete: true,
          confirmedPii: {
            dob: true,
            street: true,
            dlNumber: true
          }
        }
      });
    }

    yield all([
      put({
        type: 'GET_ALL_DRIVERS_SUCCESS',
        payload: {
          drivers,
          quoteId,
          personId,
          primaryDriverEmail: email || primaryDriver.email
        }
      }),
      put({
        type: 'GET_ALL_VEHICLES_SUCCESS',
        payload: { vehicles }
      }),
      put({
        type: 'SAVE_COVERAGE_SUCCESS',
        payload: {
          coverage: { ...retrievedCoverage }
        }
      }),
      put({
        type: 'SAVE_MILEAGE_SUCCESS',
        payload: {
          mileage: roundedMileage || 15,
          savedMileage: !!mileage
        }
      }),
      put({
        type: 'SAVE_PERSONA_SUCCESS',
        payload: { personaCode }
      })
    ]);

    // determine where to drop user off in flow
    yield put({
      type: 'GET_CHECKPOINT',
      payload: {
        quoteComplete,
        showTrial
      }
    });
  } catch (error) {
    if (error && error.status) {
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    }
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });
    // Set fingerprint and capture exception in Sentry
    Sentry.withScope(scope => {
      scope.setFingerprint([window.location.pathname]);
    });
  }
}

/**
 * Drops user in quote flow near where they left off
 * If user has completed a quote, drop them at review quote screen
 * @param {boolean} quoteComplete
 * @param {boolean} showTrial
 */
export function* checkpointSubroutine({
  payload: { quoteComplete, showTrial }
}) {
  const {
    drivers: {
      primaryDriver: { primaryDriverComplete, state }
    },
    vehicles,
    coverage: { savedCoverage, coverageSelections, savedMileage },
    app: { isRideshare },
    policy: { purchased }
  } = yield select();

  if (state) {
    FullStory.setUserVars({
      garagingState_str: state
    });
    Sentry.setTag('garaging_state', state);
  }

  Sentry.setTag('quote_complete', quoteComplete);
  Sentry.setTag('primary_driver_complete', primaryDriverComplete);
  Sentry.setTag('vehicles_complete', isRideshare === 'No');
  Sentry.setTag('purchased', purchased);

  if (quoteComplete && !purchased) {
    // Set active_trial and quote_complete tags in Sentry
    Sentry.setTag('active_trial', showTrial);

    const queryParams = { isQuoteRetrieval: true, savePackage: false };

    if (primaryDriverComplete && state) {
      yield put({ type: 'UPDATE_POLICY', payload: { state } });
    }
    // we don't need to pass selected package in for quote retrieval
    yield putResolve({
      type: 'GET_RATE_BY_PACKAGE',
      payload: { queryParams },
      shouldRedirect: true,
      showTrial
    });

    return;
  }

  // if user hasn't finished all primary driver questions, drop them at the first one
  if (!primaryDriverComplete && !quoteComplete) {
    yield put({
      type: 'REDIRECT',
      payload: {
        url: '/start-quote/name'
      }
    });

    return;
  }

  // if primary driver is complete, and vehicle section isn't complete yet
  // (users dnq if app.isRideshare !== No, so they haven't finished the vehicle section yet)
  // drop users at driver list to add/remove drivers
  if (primaryDriverComplete && isRideshare !== 'No' && !quoteComplete) {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });

    yield put({
      type: 'REDIRECT',
      payload: {
        url: '/add-drivers/drivers-list'
      }
    });

    return;
  }

  // if vehicle section is complete but coverages haven't been saved yet,
  // drop user at driving behavior start page
  if (
    primaryDriverComplete &&
    isRideshare === 'No' &&
    !savedCoverage &&
    !quoteComplete
  ) {
    yield put({
      type: 'REDIRECT',
      payload: {
        url: '/driving-behavior/start'
      }
    });

    return;
  }

  // user has saved their coverages but not finished the driving behavior section
  if (savedCoverage && !savedMileage && coverageSelections instanceof Object) {
    const activeVehicles = vehicles.items.filter(vehicle => vehicle.activeBit);

    // check that we have the right number of coverages based on number of active vehicles on quote
    const completeAddDeductibles =
      Object.keys(coverageSelections).length === activeVehicles.length * 3;

    // vehicle coverages are complete, redirect to mileage screen
    if (completeAddDeductibles) {
      yield put({
        type: 'REDIRECT',
        payload: {
          url: '/driving-behavior/mileage'
        }
      });

      return;
    }
  }

  // fallback, send them to start of the flow
  yield put({
    type: 'REDIRECT',
    payload: {
      url: '/start-quote/name'
    }
  });
}

/**
 * Fetch lead data to prefill quote flow
 */
export function* fetchLeadData({ payload }) {
  const { leadType, leadId } = payload;

  if (leadType === 'everQuoteId') {
    // Set everquote id tag in Sentry
    Sentry.withScope(scope => {
      scope.setTag('everquote_id', leadId);
    });
  } else if (leadType === 'leadCloudId') {
    // Set lead cloud id tag in Sentry
    Sentry.withScope(scope => {
      scope.setTag('lead_cloud_id', leadId);
    });
  }

  try {
    const { firstName, lastName, dob } = yield call(Noblr.prefill.getLeadData, {
      [leadType]: leadId
    });

    if (!firstName && !lastName && !dob) {
      yield put({
        type: 'GET_LEAD_DATA_FAILURE'
      });
    } else {
      yield put({
        type: 'GET_LEAD_DATA_SUCCESS'
      });

      // update primary driver with data from leadcloud
      yield put({
        type: 'UPDATE_PRIMARY_DRIVER',
        payload: {
          firstName,
          lastName,
          dob
        }
      });
    }
  } catch (error) {
    // Set fingerprint in Sentry and capture the exception

    yield put({
      type: 'GET_LEAD_DATA_FAILURE'
    });
  }
}

/**
 * Fetch data from everQuote to prefill quote flow
 */
export function* fetchEverQuoteData({ payload }) {
  // Set lead cloud id tag in Sentry
  Sentry.withScope(scope => {
    scope.setTag('ever_quote_id', payload);
  });

  try {
    const { firstName, lastName, dob } = yield call(
      Noblr.prefill.getLeadData,
      payload
    );

    if (!firstName && !lastName && !dob) {
      yield put({
        type: 'GET_LEAD_DATA_FAILURE'
      });
    } else {
      yield put({
        type: 'GET_LEAD_DATA_SUCCESS'
      });

      // update primary driver with data from leadcloud
      yield put({
        type: 'UPDATE_PRIMARY_DRIVER',
        payload: {
          firstName,
          lastName,
          dob
        }
      });
    }
  } catch (error) {
    yield put({
      type: 'GET_LEAD_DATA_FAILURE'
    });
  }
}

/**
/**
 * Clear quote flow cookies
 * Optionally redirect user to start of quote flow
 * @param {Object} action
 */
export function* resetQuote(action) {
  const { payload = null } = action;

  yield put({ type: 'TOGGLE_LOADER', payload: { toShow: true } });

  if (!payload) {
    yield put({ type: 'TOGGLE_LOADER', payload: { toShow: false } });

    return;
  }

  const { urlParams = '', redirect = false } = payload;
  const personIdCookie = cookies.get(configureCookieName('personId'));
  const sessionCookie = cookies.get(configureCookieName('session'));
  const quoteIdCookie = cookies.get(configureCookieName('quoteId'));

  if (sessionCookie && quoteIdCookie) {
    cookies.remove(
      configureCookieName('session'),
      EXPIRES_COOKIE_HEADER_CONFIG
    );
    cookies.remove(
      configureCookieName('quoteId'),
      EXPIRES_COOKIE_HEADER_CONFIG
    );
  }

  // We never reset personId Cookie
  // personId cookie is passed to register quote
  if (personIdCookie) {
    yield putResolve({
      type: 'REGISTER_QUOTE',
      payload: { personId: personIdCookie, source: 'Web' }
    });
    FullStory.identify(personIdCookie, {
      personId_str: personIdCookie,
      resetQuote_bool: true
    });

    Sentry.setUser({ id: personIdCookie });
    Sentry.setTag('reset_quote', true);
  }

  // redirect with url parameters if payload is passed in
  if (redirect && urlParams.length) {
    yield delay(500);

    yield put({
      type: 'REDIRECT',
      payload: {
        url: `/start-quote/name${urlParams}`
      }
    });
  }
  yield put({ type: 'TOGGLE_LOADER', payload: { toShow: false } });
}

/**
 * Hit endpoint to track screen visit
 * @param {Object} payload - payload passed in with action
 * @property {string} encString
 * @property {string} screenIdentifier
 */
export function* trackScreenVisited({ payload }) {
  const {
    drivers: {
      primaryDriver: { personId }
    }
  } = yield select();

  if (!personId) {
    return;
  }

  FullStory.identify(personId, {
    personId_str: personId
  });

  Sentry.setUser({ id: personId });
  Sentry.setTag('visited_screen_name', payload.screenIdentifier);

  const payloadToSave = payload.encString
    ? {
        screenName: payload.screenIdentifier,
        quoteRetrievalEncString: payload.encString
      }
    : { screenName: payload.screenIdentifier };

  try {
    yield call(Noblr.tools.trackScreenVisited, payloadToSave, {
      personId
    });
  } catch (error) {
    // no error is returned
    if (error) {
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    } else {
      yield put({
        type: 'TRACK_SCREEN_VIEW_FAILURE'
      });
    }
  }
}

export function* watchSectionRequests() {
  const requestChan = yield actionChannel('SET_SECTION');

  yield takeEvery(requestChan, salesForceGateCompleteRoutine);
}

export function* salesForceGateCompleteRoutine({ payload: { section } }) {
  const {
    app: { prevSection }
  } = yield select();

  /*
    Do not evaluate current section in quote flow if the user is registering a new quote, requesting rates, purchased the quote, is on trial or setting up an account, was DNQ'd or was Soft Stopped
  */
  if (
    !section ||
    (section === 'start-quote' && !prevSection) ||
    (!section && prevSection === 'start-quote') ||
    section === 'quote' ||
    section === 'purchase' ||
    section === 'account' ||
    section === 'driver-exclusion' ||
    section === 'dnq' ||
    section === 'call-to-complete'
  ) {
    return;
  }

  let { gate = null, lastSection, nextSection } = SALES_FORCE_GATES[section];

  /*
    'allowCustomCoverages' is "true" in PA and MD quote flow.
    The 'select-coverages' section is skipped if 'allowCustomCoverages' is 'true' so we have to change the 'gate', 'lastSection' and 'nextSection' manually in order for the SF Gate Complete request to be successful.
  */
  if (section === 'driving-behavior' && prevSection !== 'quote') {
    gate = 'VEHICLES_COMPLETE';
    lastSection = 'add-vehicles';
    nextSection = 'quote';
  }

  if (
    prevSection &&
    lastSection &&
    prevSection === lastSection &&
    prevSection !== nextSection &&
    gate
  ) {
    try {
      yield call(Noblr.salesForce.gateComplete, gate);

      // Remove section to prevent multiple calls
      yield put({
        type: 'SAVE_SAVE_CRM_GATE_SUCCESS'
      });
    } catch (error) {
      yield put({ type: 'SAVE_CRM_GATE_FAILURE', error });
    }
  }
}

export function* requestUSAAEligibilityQuestionsWatcher() {
  try {
    const response = yield call(Noblr.eligibility.getEligibilityQuestions);

    yield put({
      type: 'REQUEST_USAA_ELIGIBILITY_QUESTIONS_SUCCESS',
      payload: response
    });
  } catch (error) {
    yield put({
      type: 'REQUEST_USAA_ELIGIBILITY_QUESTIONS_FAILURE',
      error: error.data
    });

    if (error.status === 400) {
      yield put({
        type: 'SET_ERROR',
        error: { critical: true, ...error.data }
      });
    }
  }
}

export function* primaryEligibilityQuestionSubroutine({ payload }) {
  // Extract key/value pair from incoming payload
  const [primaryEligibilityPayload] = Object.entries(payload);
  // De-structure eligibilityQuestionId and answer from Object entries
  const [eligibilityQuestionId, answer] = primaryEligibilityPayload;

  try {
    // send "eligibilityQuestionId" and "answer" to backend
    yield call(Noblr.eligibility.sendPrimaryEligibilityAnswer, {
      eligibilityQuestionId,
      answer
    });

    // set default success route if answer is "YESs"
    let redirectURL = '/start-quote/email';

    // if answer is "I_DONT_KNOW", redirect to secondary eligibility page
    if (answer !== 'YES') {
      // update url
      redirectURL = '/start-quote/usaa-eligibility';

      // exit out of generator effect and wait for next action to be dispatched
    } else {
      // potential member is already a USAA member so request prefill info
      yield putResolve({ type: 'REQUEST_PREFILL' });
    }

    // dispatch SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_SUCCESS action type
    yield putResolve({
      type: 'SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_SUCCESS',
      payload: { isUSAAMember: answer === 'YES' }
    });
    // hide loader from page
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });

    // dispatch 'REDIRECT' action type and pass redirect URL
    yield put({ type: 'REDIRECT', payload: { url: redirectURL } });
  } catch (error) {
    // handle error and pass to reducer
    yield put({
      type: 'SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_FAILURE',
      error
    });

    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

export function* secondaryEligibilityQuestionsSubroutine({
  payload,
  successRoute
}) {
  // Extract key/value pairs from payload
  const answers = Object.entries(payload);
  // reduce key/value pairs in answers 2D array
  const requestBody = answers.reduce((acc, value) => {
    const [eligibilityQuestionId, answer] = value;
    // set "eligibilityQuestionId" and "answer" in Object
    const radioSelection = {
      eligibilityQuestionId,
      answer
    };

    // push object into accumulator
    acc.push(radioSelection);

    return acc;
  }, []);

  try {
    // send "eligibilityQuestionId" and "answer" for each answer to backend
    yield call(Noblr.eligibility.sendSecondaryEligibilityAnswer, requestBody);
    // potential member is already a USAA member so request prefill info
    yield putResolve({ type: 'REQUEST_PREFILL' });
    // dispatch SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_SUCCESS action type
    yield putResolve({
      type: 'SUBMIT_SECONDARY_ELIGIBILITY_ANSWER_SUCCESS',
      payload: { isUSAAMember: true } // assume eligibility if request didn't error due to DNQ
    });
    // dispatch 'REDIRECT' action type and pass redirect URL
    yield put({ type: 'REDIRECT', payload: { url: successRoute } });
  } catch (error) {
    yield put({ type: 'TOGGLE_LOADER', payload: { toShow: false } });
    // handle error and pass to reducer
    yield put({
      type: 'SUBMIT_SECONDARY_ELIGIBILITY_ANSWER_FAILURE',
      error
    });
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

export function* driversLicenseVerificationSubroutine({
  payload,
  successRoute
}) {
  const primaryDriverPayload = { ...payload };
  // Extract primary driver's driverId from driver reducer
  const {
    app: { requiresDriverExclusion },
    drivers: {
      primaryDriver: { driverId }
    }
  } = yield select();

  // Set driverId on primaryDriverPayload Object
  primaryDriverPayload.driverId = driverId;

  // request body expects an Array of Driver Objects
  // place primary driver Object in request Array
  const request = [primaryDriverPayload];

  try {
    const response = yield call(Noblr.driver.verifyDriversLicenses, request);
    const { overallVerificationSuccess } = response;

    // if all drivers licenses were verified
    if (overallVerificationSuccess) {
      yield put({
        type: 'VERIFY_DRIVERS_LICENSES_SUCCESS',
        payload: overallVerificationSuccess
      });

      if (requiresDriverExclusion) {
        // send request for un-obfuscated driver names
        yield putResolve({
          type: 'GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES'
        });
      }
      // redirect to the effective date or vin re-rate page
      yield put({
        type: 'REDIRECT',
        payload: { url: successRoute }
      });
    } else {
      yield put({
        type: 'VERIFY_DRIVERS_LICENSES_FAILURE',
        payload: overallVerificationSuccess
      });

      // otherwise, redirect the user to the verification problem page
      yield put({
        type: 'REDIRECT',
        payload: { url: '/purchase/dl-verification-issue' }
      });
    }
  } catch (error) {
    yield put({
      type: 'VERIFY_DRIVERS_LICENSES_ERROR',
      error
    });
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/*
  Generator Effect That is Specific To GA
  Action "payload" contains "defensiveCourseTaken"
  "defensiveCourseTaken" is either "Yes" or "No" and must be formatted to boolean before sending in request body to backend
*/
export function* updateSafeDrivingAnswerSubroutine({
  payload: { defensiveCourseTaken },
  successRoute,
  formikActions: { setSubmitting }
}) {
  const {
    rate: { currentPackage, savedCoverages },
    excludedDrivers: { requiresUpdatedRates, updatedRates, allDriversExcluded }
  } = yield select();

  try {
    const formattedDefensiveCourseTakenAnswer = defensiveCourseTaken === 'Yes';

    yield call(Noblr.quote.updateDefensiveCourseTakenStatus, {
      defensiveCourseTaken: formattedDefensiveCourseTakenAnswer
    });
    yield put({
      type: 'UPDATE_DEFENSIVE_DRIVING_ANSWER_SUCCESS'
    });

    // specific for GA in driver exclusion flow
    if (
      successRoute === '/purchase/effective-date' &&
      allDriversExcluded &&
      requiresUpdatedRates &&
      !updatedRates
    ) {
      yield putResolve({
        type: 'GET_RATE_BY_PACKAGE',
        payload: {
          currentPackage,
          queryParams: { savePackage: true },
          ...savedCoverages
        }
      });

      yield putResolve({
        type: 'UPDATED_RATES_REQUESTED'
      });
    }
    yield put({
      type: 'REDIRECT',
      payload: { url: successRoute }
    });
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error
    });
    yield put({ type: 'UPDATE_DEFENSIVE_DRIVING_ANSWER_FAILURE', error });
  } finally {
    yield setSubmitting(false);
  }
}

/*
  Generator Effect That is Specific To GA
  Action "payload" contains "priorInsuranceLapseReason"
*/
export function* priorInsuranceLapseSubroutine({
  payload: { priorInsuranceLapseReason },
  successRoute
}) {
  try {
    // Send "priorInsuranceLapseReason" in PUT request to BE
    yield call(Noblr.quote.updatePriorInsuranceLapseReason, {
      priorInsuranceLapseReason
    });
    yield put({ type: 'UPDATE_PRIOR_INSURANCE_LAPSE_REASON_SUCCESS' });
    yield put({ type: 'REDIRECT', payload: { url: successRoute } });
  } catch (error) {
    yield put({ type: 'UPDATE_PRIOR_INSURANCE_LAPSE_REASON_FAILURE', error });
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

export function* requestPriorInsuranceReasonsWatcher() {
  try {
    const priorInsuranceOptions = yield call(
      Noblr.quote.getPriorInsuranceLapseOptions
    );

    const filteredOptions = priorInsuranceOptions
      .filter(
        item =>
          item.option === 'ARMED_FORCES' || // specific to IN
          item.option === 'MILITARY_DEPLOYMENT' || // specific to GA
          item.option === 'OTHER'
      )
      .map(item => {
        return { value: item.option, label: item.label };
      });

    yield put({
      type: 'REQUEST_PRIOR_INSURANCE_REASONS_SUCCESS',
      payload: filteredOptions
    });
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
    yield put({
      type: 'REQUEST_PRIOR_INSURANCE_REASONS_FAILURE',
      error
    });
  }
}
export function* usaaUmbrellaPolicyWatcher({ payload: { umbrellaPolicy } }) {
  yield put({ type: 'TOGGLE_LOADER', payload: { toShow: true } });

  const successRoute =
    umbrellaPolicy === 'true'
      ? '/purchase/umbrella-policy-acknowledgement'
      : '/purchase/payment-info';

  yield put({
    type: 'REDIRECT',
    payload: {
      url: successRoute
    }
  });
  yield put({ type: 'TOGGLE_LOADER', payload: { toShow: false } });
}

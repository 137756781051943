/* eslint-disable default-param-last */
import { buildVehicleCoverages } from 'utilities/coverage';
import {
  GET_PRODUCT_DATA,
  GET_PRODUCT_DATA_SUCCESS,
  GET_PRODUCT_DATA_FAILURE,
  GET_PREFILL_COVERAGES,
  SAVE_MILEAGE_SUCCESS,
  UPDATE_MILEAGE_SUCCESS,
  GET_MILEAGE,
  GET_MILEAGE_SUCCESS,
  GET_MILEAGE_FAILURE,
  GET_PERSONA,
  GET_PERSONA_SUCCESS,
  GET_PERSONA_FAILURE,
  SAVE_PERSONA,
  SAVE_PERSONA_SUCCESS,
  SAVE_PERSONA_FAILURE,
  RESTRICT_UM_OPTIONS,
  UPDATE_PERSONA,
  UPDATE_PERSONA_SUCCESS,
  UPDATE_PERSONA_FAILURE,
  UPDATE_MILEAGE_AND_PERSONA,
  UPDATE_MILEAGE_AND_PERSONA_SUCCESS,
  UPDATE_MILEAGE_AND_PERSONA_FAILURE,
  SAVE_COVERAGE_SUCCESS,
  UPDATE_COVERAGE_SUCCESS,
  SAVE_MULTIPLE_DEDUCTIBLES,
  SAVE_MULTIPLE_DEDUCTIBLES_SUCCESS,
  SAVE_MULTIPLE_DEDUCTIBLES_FAILURE,
  UPDATE_MULTIPLE_DEDUCTIBLES,
  UPDATE_MULTIPLE_DEDUCTIBLES_SUCCESS,
  UPDATE_MULTIPLE_DEDUCTIBLES_FAILURE,
  GET_VEHICLE_DEDUCTIBLES_SUCCESS,
  SET_FILTERED_COVERAGES,
  RESTRICT_TO_UMPD,
  CLEAR_UMPD_RESTRICTION,
  UPDATE_COVERAGE_RULE_OPTIONS,
  CLEAR_COVERAGE_RULE_OPTIONS,
  SET_CURRENT_PACKAGE,
  GET_RATE_BY_PACKAGE_SUCCESS
} from '../actions';

const INITIAL_COVERAGE_STATE = {
  sectionKey: '',
  errorMessage: '',
  bestMatch: null,
  bestMatchIdx: null,
  summaryDisclosureLink: '',
  coverageOptions: [],
  coverages: {},
  config: { allIds: [], policyCoverages: [], vehicleCoverages: [], byId: {} },
  vehicleCoverages: [],
  loadingProduct: false,
  coverageSelections: null,
  pendingSave: false,
  personaCode: 1,
  mileage: 15,
  savedMileage: false,
  savedCoverage: false,
  savedVehicleIds: [],
  policyCoverageKeys: [],
  restrictedFields: [],
  isSaving: false,
  coverageRuleOptions: {},
  defaultPackage: 'moderate',
  currentPackage: 'moderate',
  personaSaved: false,
  loading: false,
  coveragePackages: { allIds: [], byId: {} },
  rulesByCoverage: {},
  restrictUM: null
};

const coverageReducer = (state = INITIAL_COVERAGE_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCT_DATA:
      return {
        ...INITIAL_COVERAGE_STATE,
        loadingProduct: true
      };

    case GET_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
        currentPackage: payload.currentPackage,
        savedCoverage: payload.currentPackage !== state.defaultPackage,
        loadingProduct: false
      };

    case GET_PRODUCT_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingProduct: false
      };

    case GET_PREFILL_COVERAGES:
      return {
        ...state,
        payload
      };

    case GET_MILEAGE:
      return {
        ...state,
        loading: true
      };

    case GET_MILEAGE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false
      };

    case GET_MILEAGE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      };

    case SAVE_MILEAGE_SUCCESS:
      return {
        ...state,
        ...payload
      };

    case UPDATE_MILEAGE_SUCCESS:
      return {
        ...state,
        ...payload
      };

    case GET_PERSONA:
      return {
        ...state,
        loading: true
      };

    case GET_PERSONA_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false
      };

    case GET_PERSONA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case SAVE_PERSONA:
      return {
        ...state,
        personaSaved: false,
        isSaving: true,
        error: null
      };

    case SAVE_PERSONA_SUCCESS:
      return {
        ...state,
        ...payload,
        personaSaved: true,
        isSaving: false
      };

    case SAVE_PERSONA_FAILURE:
      return {
        ...state,
        personaSaved: false,
        isSaving: false
      };

    case UPDATE_PERSONA:
      return {
        ...state,
        isSaving: true,
        error: null
      };

    case UPDATE_PERSONA_SUCCESS:
      return {
        ...state,
        ...payload
      };

    case UPDATE_PERSONA_FAILURE:
      return {
        ...state,
        error: action.error,
        personaSaved: false,
        isSaving: false
      };

    case UPDATE_MILEAGE_AND_PERSONA:
      return {
        ...state,
        isSaving: true
      };

    case UPDATE_MILEAGE_AND_PERSONA_SUCCESS:
      return {
        ...state,
        ...payload,
        isSaving: false
      };

    case UPDATE_MILEAGE_AND_PERSONA_FAILURE:
      return {
        ...state,
        error: action.error,
        isSaving: false
      };

    case SAVE_COVERAGE_SUCCESS:
      return {
        ...state,
        ...payload,
        savedCoverage: true
      };

    case CLEAR_COVERAGE_RULE_OPTIONS:
      return {
        ...state,
        coverageRuleOptions: {}
      };

    case UPDATE_COVERAGE_RULE_OPTIONS: {
      const coverageFieldNames = Object.keys(payload);
      const updatedCoverageRuleOptions = coverageFieldNames.reduce(
        (acc, coverageName) => {
          const updatedRulesConfig = { ...payload[coverageName] };

          if (Object.keys(updatedRulesConfig).length) {
            if (!acc[coverageName]) {
              acc[coverageName] = {};
            }

            if (updatedRulesConfig.updatedValue) {
              acc[coverageName].updatedValue = updatedRulesConfig.updatedValue;
            }

            if (updatedRulesConfig.allowedOptions) {
              acc[coverageName].allowedOptions =
                updatedRulesConfig.allowedOptions;
            }

            if (updatedRulesConfig.errorMessages) {
              acc[coverageName].errorMessages =
                updatedRulesConfig.errorMessages;
            }
          }

          return acc;
        },
        { ...state.coverageRuleOptions }
      );

      return {
        ...state,
        coverageRuleOptions: updatedCoverageRuleOptions
      };
    }

    case RESTRICT_UM_OPTIONS:
      return {
        ...state,
        restrictUM: payload,
        coverageRuleOptions: {}
      };

    case UPDATE_COVERAGE_SUCCESS:
      return {
        ...state,
        ...payload
      };

    case SAVE_MULTIPLE_DEDUCTIBLES:
      // eslint-disable-next-line no-case-declarations
      const coverageSelections = buildVehicleCoverages(payload);

      return {
        ...state,
        coverageSelections: {
          ...state.coverageSelections,
          ...coverageSelections
        },
        pendingSave: true
      };

    case SAVE_MULTIPLE_DEDUCTIBLES_SUCCESS:
      return {
        ...state,
        pendingSave: false,
        savedVehicleIds: [...state.savedVehicleIds, ...payload.savedVehicleIds]
      };

    case SAVE_MULTIPLE_DEDUCTIBLES_FAILURE:
      return {
        ...state,
        error: action.error,
        pendingSave: false
      };

    case UPDATE_MULTIPLE_DEDUCTIBLES:
      // eslint-disable-next-line no-case-declarations
      const coverageSelectionsToUpdate = buildVehicleCoverages(payload);

      return {
        ...state,
        coverageSelections: {
          ...state.coverageSelections,
          ...coverageSelectionsToUpdate
        },
        pendingSave: true
      };

    case UPDATE_MULTIPLE_DEDUCTIBLES_SUCCESS:
      return {
        ...state,
        pendingSave: false,
        savedVehicleIds: [...state.savedVehicleIds, ...payload.savedVehicleIds]
      };

    case UPDATE_MULTIPLE_DEDUCTIBLES_FAILURE:
      return {
        ...state,
        error: action.error,
        pendingSave: false
      };

    case GET_VEHICLE_DEDUCTIBLES_SUCCESS:
      return {
        ...state,
        coverageSelections: payload
      };

    case SET_FILTERED_COVERAGES:
      return {
        ...state,
        vehicleCoverages: payload.filteredCoverages
      };

    case RESTRICT_TO_UMPD:
      return {
        ...state,
        restrictedFields: [...state.restrictedFields, payload.fieldName]
      };

    case CLEAR_UMPD_RESTRICTION:
      return {
        ...state,
        restrictedFields: state.restrictedFields.filter(
          field => field !== payload.fieldName
        )
      };

    case SET_CURRENT_PACKAGE:
      return {
        ...state,
        currentPackage: payload
      };

    case GET_RATE_BY_PACKAGE_SUCCESS:
      return {
        ...state,
        currentPackage: payload.currentPackage
      };

    default:
      return state;
  }
};

export default coverageReducer;

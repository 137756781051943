import watchAsyncRequests from 'utilities/asyncListener';
import {
  takeLatest,
  takeLeading,
  takeEvery,
  all,
  fork,
  spawn
} from 'redux-saga/effects';
import {
  editingItemWatcher,
  removeItemWatcher,
  prefillRequestWatcher,
  displayAsyncError,
  clearAsyncStatus,
  setErrorLevel,
  retrieveQuoteSubroutine,
  retrieveUSAAPersonInfoSubroutine,
  verifyUSAAPersonAndRetrieveQuoteSubroutine,
  undoRemoveWatcher,
  fetchLeadData,
  resetQuote,
  trackScreenVisited,
  checkpointSubroutine,
  salesForceGateCompleteRoutine,
  watchSectionRequests,
  fetchQuoteDataSubroutine,
  requestUSAAEligibilityQuestionsWatcher,
  primaryEligibilityQuestionSubroutine,
  secondaryEligibilityQuestionsSubroutine,
  driversLicenseVerificationSubroutine,
  updateSafeDrivingAnswerSubroutine,
  priorInsuranceLapseSubroutine,
  requestPriorInsuranceReasonsWatcher,
  usaaUmbrellaPolicyWatcher
} from './app';
import {
  createAccountSubroutine,
  createNoblrAccountSubroutine,
  userLoginSubroutine,
  loginNoblrAccountSubroutine,
  confirmTrialUserEmailSubroutine,
  sendOneTimePassSubroutine,
  verifyOTPSubroutine,
  resetPasswordSubroutine
  // authenticateSalesToolLink
} from './auth';
import {
  removeDriverWatcher,
  getAllDriversWatcher,
  getAllDriversSuccess,
  saveAdditionalDriverSubroutine,
  updateAdditionalDriverInfoSubroutine,
  getAssociatedDriversSubroutine,
  activeDriverLicenseStatusSubroutine,
  confirmExcludedDriverWatcher
} from './drivers';
import {
  updatedAssociatedVehicles,
  removeVehicle,
  submitRideshareStatusSubroutine,
  checkAmpOwnsAllVehicles,
  getVehicleDNQ,
  saveVINSubroutine,
  getAllVehicles,
  getAllVehiclesSuccess,
  updateVehicleSubroutine,
  undoRemoveVehicle,
  getYearMakeModelRequest,
  vehicleMakeModelWatcher,
  vehicleModelStyleWatcher,
  saveVINStem,
  assignPrimaryCarToDriver,
  assignExcessVehiclesSubroutine,
  setPrimaryVehicleAssignmentLocallySubroutine,
  assignVehiclesSubroutine
} from './vehicles';
import {
  savePrimaryDriverSubroutine,
  saveOrUpdatePrimaryDriverSubroutine,
  savePrimaryPersonAddressSubroutine,
  registerQuoteSubroutine,
  saveDlInfo,
  checkPersonStatusWatcher,
  setHomeOwnerSubroutine,
  updateDriverAndVerifyQuoteRoutine
} from './person';
import {
  getMileage,
  saveMileage,
  updateMileage,
  getPersona,
  savePersona,
  updatePersona,
  updateMileageAndPersonaRoutine,
  getCoverage,
  saveSelectedCoverageType,
  updateCoverage,
  saveDeductiblesArray,
  updateDeductiblesArray,
  productDataV2Subroutine,
  productDataWatcher
} from './coverage';
import {
  createQuoteSubroutine,
  getPrebindApplication,
  getMembershipApplication,
  getUmuimOfferForm,
  updatePreBindRateFieldsWatcher,
  signUimbiForm,
  signTortOptionsForm,
  signUmbiForm,
  signInsuranceApplication,
  signMembershipApplication,
  signUmuimOfferFormWatcher,
  setPaymentSchedule,
  savePaymentInfo,
  updatePhoneAndEmail,
  bindQuote,
  updateToTrial,
  verifyVINsSubroutine,
  sendRefusalToWriteEmailSubroutine,
  prebindPDFWatcher,
  watchPrebindSignature,
  getDynamicPreBindFormByIdSubroutine,
  submitFormIdSignatureWatcher,
  requestDynamicPreBindFormsSubroutine
} from './policy';
import {
  getRateRequest,
  orderMVRSubroutine,
  getRateByPackageSubroutine,
  getCustomCoverageRateSubroutine,
  saveSelectedPackageSubroutine
} from './rate';
import {
  recaptchaSubroutine,
  determineQuoteAutomationStatus
} from './reCaptcha';
// Excluded Drivers Saga Generator Effects
import {
  excludeDriverWatcher,
  updateExcludedDriverInfoSubroutine,
  requestRemovedAndExcludedDriverNamesSubroutine
} from './excludedDrivers';

function* watchAppActions() {
  yield takeLatest('EDITING_ITEM', editingItemWatcher);
  yield takeLatest('REMOVE_ITEM', removeItemWatcher);
  yield takeLatest('UNDO_REMOVE', undoRemoveWatcher);
  yield takeEvery('DISPLAY_ASYNC_ERROR', displayAsyncError);
  yield takeLatest('REQUEST_PREFILL', prefillRequestWatcher);
  yield takeLatest('GET_RATE_BY_PACKAGE', getRateByPackageSubroutine);
  yield takeLatest('GET_CUSTOM_COVERAGE_RATE', getCustomCoverageRateSubroutine);
  yield takeLatest('GET_RATE', getRateRequest);
  yield takeLatest('ORDER_MVR', orderMVRSubroutine);
  yield takeEvery(
    ['SET_ASYNC_FAILED_STATUS', 'SET_ASYNC_SUCCESS_STATUS'],
    clearAsyncStatus
  );
  yield takeLatest('SET_ERROR', setErrorLevel);
  yield takeLatest('RETRIEVE_QUOTE', retrieveQuoteSubroutine);
  yield takeLeading(
    'RETRIEVE_USAA_PERSON_INFO',
    retrieveUSAAPersonInfoSubroutine
  );
  yield takeLatest(
    'VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE',
    verifyUSAAPersonAndRetrieveQuoteSubroutine
  );
  yield takeLatest('GET_LEAD_DATA', fetchLeadData);
  yield takeLatest('RESET_QUOTE', resetQuote);
  yield takeEvery('TRACK_SCREEN_VIEW', trackScreenVisited);
  yield takeEvery('GET_CHECKPOINT', checkpointSubroutine);
  yield takeLatest('SAVE_CRM_GATE', salesForceGateCompleteRoutine);
  yield takeLatest('SAVE_SELECTED_PACKAGE', saveSelectedPackageSubroutine);
  yield takeLatest('FETCH_QUOTE_DATA', fetchQuoteDataSubroutine);
  yield takeLeading(
    'REQUEST_USAA_ELIGIBILITY_QUESTIONS',
    requestUSAAEligibilityQuestionsWatcher
  );
  yield takeLatest(
    'SUBMIT_PRIMARY_ELIGIBILITY_ANSWER',
    primaryEligibilityQuestionSubroutine
  );
  yield takeLatest(
    'SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS',
    secondaryEligibilityQuestionsSubroutine
  );
  yield takeLatest(
    'VERIFY_DRIVERS_LICENSES',
    driversLicenseVerificationSubroutine
  );
  yield takeLatest(
    'UPDATE_DEFENSIVE_DRIVING_ANSWER',
    updateSafeDrivingAnswerSubroutine
  );
  yield takeLatest(
    'UPDATE_PRIOR_INSURANCE_LAPSE_REASON',
    priorInsuranceLapseSubroutine
  );
  yield takeLatest(
    'REQUEST_PRIOR_INSURANCE_REASONS',
    requestPriorInsuranceReasonsWatcher
  );
  yield takeLatest('USAA_UMBRELLA_POLICY', usaaUmbrellaPolicyWatcher);
}

function* watchAuthActions() {
  yield takeLatest('CREATE_ACCOUNT', createAccountSubroutine);
  yield takeLatest('CREATE_NOBLR_ACCOUNT', createNoblrAccountSubroutine);
  yield takeLatest('LOGIN_USER', userLoginSubroutine);
  yield takeLatest('LOGIN_NOBLR_ACCOUNT', loginNoblrAccountSubroutine);
  yield takeLatest('CONFIRM_EMAIL', confirmTrialUserEmailSubroutine);
  yield takeLatest('SEND_ONE_TIME_PASS', sendOneTimePassSubroutine);
  yield takeLatest('VERIFY_OTP', verifyOTPSubroutine);
  yield takeLatest('RESET_PASSWORD', resetPasswordSubroutine);
  // yield takeLatest('VERIFY_EMAIL_LINK', authenticateSalesToolLink);
}

// Our watcher Saga: watches for actions to dispatch worker Saga
function* watchPersonActions() {
  yield takeEvery('SAVE_PRIMARY_DRIVER', savePrimaryDriverSubroutine);
  yield takeLatest(
    'UPDATE_PRIMARY_DRIVER_REQUEST',
    saveOrUpdatePrimaryDriverSubroutine
  );
  yield takeLatest('SAVE_DL_INFO', saveDlInfo);
  yield takeLatest(
    'SAVE_PRIMARY_PERSON_ADDRESS',
    savePrimaryPersonAddressSubroutine
  );
  yield takeLatest('CHECK_PERSON_STATUS', checkPersonStatusWatcher);
  yield takeLeading('REGISTER_QUOTE', registerQuoteSubroutine);

  yield takeLatest('SET_HOMEOWNER', setHomeOwnerSubroutine);
  yield takeLatest(
    'UPDATE_PRIMARY_DRIVER_AND_VERIFY_QUOTE',
    updateDriverAndVerifyQuoteRoutine
  );
}

function* watchDriversActions() {
  yield takeLatest(
    'SAVE_ADDITIONAL_DRIVER_INFO',
    saveAdditionalDriverSubroutine
  );
  yield takeLatest(
    'UPDATE_ADDITIONAL_DRIVER_INFO',
    updateAdditionalDriverInfoSubroutine
  );
  yield takeLatest('GET_ASSOCIATED_DRIVERS', getAssociatedDriversSubroutine);
  yield takeLatest('REMOVE_DRIVER', removeDriverWatcher);
  yield takeLatest('GET_ALL_DRIVERS', getAllDriversWatcher);
  yield takeLatest('GET_ALL_DRIVERS_SUCCESS', getAllDriversSuccess);
  yield takeLeading(
    'SAVE_EDITING_DRIVER_DL_STATUS',
    activeDriverLicenseStatusSubroutine
  );
  yield takeLatest('CONFIRM_EXCLUDED_DRIVER', confirmExcludedDriverWatcher);
}

function* watchExcludedDriversActions() {
  yield takeLatest('EXCLUDE_DRIVER', excludeDriverWatcher);
  yield takeLatest(
    'UPDATE_EXCLUDED_DRIVER_INFO',
    updateExcludedDriverInfoSubroutine
  );
  yield takeLatest(
    'GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES',
    requestRemovedAndExcludedDriverNamesSubroutine
  );
}

function* watchVehiclesActions() {
  yield takeLatest('GET_ASSOCIATED_VEHICLES', updatedAssociatedVehicles);
  yield takeLatest('REMOVE_VEHICLE_REQUEST', removeVehicle);
  yield takeLatest('UNDO_REMOVE_VEHICLE', undoRemoveVehicle);
  yield takeLatest('SET_RIDESHARE_TNC_STATUS', submitRideshareStatusSubroutine);
  yield takeLatest('SET_VEHICLES_AMP_STATUS', checkAmpOwnsAllVehicles);
  yield takeLatest('GET_VEHICLE_DNQ', getVehicleDNQ);
  yield takeLatest('SAVE_VIN', saveVINSubroutine);
  yield takeLatest('UPDATE_VEHICLE', updateVehicleSubroutine);
  yield takeLatest('GET_ALL_VEHICLES', getAllVehicles);
  yield takeLatest('GET_ALL_VEHICLES_SUCCESS', getAllVehiclesSuccess);
  yield takeLatest('GET_YEAR_MAKE_MODEL', getYearMakeModelRequest);
  yield takeLatest('GET_SELECTED_MAKE_MODEL', vehicleMakeModelWatcher);
  yield takeLatest('GET_SELECTED_MODEL_STYLE', vehicleModelStyleWatcher);
  yield takeLatest('SAVE_VIN_STEM', saveVINStem);
  yield takeLatest('SET_PRIMARY_VEHICLE_ASSIGNMENT', assignPrimaryCarToDriver);
  yield takeLatest('ASSIGN_EXCESS_VEHICLES', assignExcessVehiclesSubroutine);
  yield takeLatest(
    'SET_PRIMARY_VEHICLE_ASSIGNMENT_LOCALLY',
    setPrimaryVehicleAssignmentLocallySubroutine
  );
  yield takeLatest('SET_VEHICLE_ASSIGNMENTS', assignVehiclesSubroutine);
}

function* watchCoverageActions() {
  yield takeLatest('GET_PRODUCT_DATA', productDataV2Subroutine);
  yield takeLatest('GET_COVERAGE', getCoverage);
  yield takeLatest('SAVE_COVERAGE', saveSelectedCoverageType);
  yield takeLatest('UPDATE_COVERAGE', updateCoverage);
  yield takeLatest('GET_MILEAGE', getMileage);
  yield takeLatest('SAVE_MILEAGE', saveMileage);
  yield takeLatest('UPDATE_MILEAGE', updateMileage);
  yield takeLatest('GET_PERSONA', getPersona);
  yield takeLatest('SAVE_PERSONA', savePersona);
  yield takeLatest('UPDATE_PERSONA', updatePersona);
  yield takeLatest(
    'UPDATE_MILEAGE_AND_PERSONA',
    updateMileageAndPersonaRoutine
  );
  yield takeLatest('SAVE_MULTIPLE_DEDUCTIBLES', saveDeductiblesArray);
  yield takeLatest('UPDATE_MULTIPLE_DEDUCTIBLES', updateDeductiblesArray);
}

function* watchPolicyActions() {
  yield takeLatest('CREATE_QUOTE', createQuoteSubroutine);
  yield takeLatest('GET_PREBIND_APPLICATION', getPrebindApplication);
  yield takeLatest('GET_MEMBERSHIP_APPLICATION', getMembershipApplication);
  yield takeLatest(
    'UPDATE_PREBIND_RATE_FIELDS',
    updatePreBindRateFieldsWatcher
  );
  yield takeLatest('GET_UMUIM_FORM', getUmuimOfferForm);
  yield takeLatest('SIGN_UMBI_FORM', signUmbiForm);
  yield takeLatest('SIGN_UIMBI_FORM', signUimbiForm);
  yield takeLatest('SIGN_TORT_OPTIONS_FORM', signTortOptionsForm);
  yield takeLatest('SIGN_INSURANCE_APPLICATION', signInsuranceApplication);
  yield takeLatest('SIGN_MEMBERSHIP_APPLICATION', signMembershipApplication);
  yield takeLatest('SIGN_UMUIM_FORM', signUmuimOfferFormWatcher);
  yield takeLatest('SET_INSTALLMENT_SCHEDULE', setPaymentSchedule);
  yield takeLatest('SAVE_PAYMENT_INFO', savePaymentInfo);
  yield takeLatest('UPDATE_PHONE_AND_EMAIL', updatePhoneAndEmail);
  yield takeLatest('BIND_QUOTE', bindQuote);
  yield takeLatest('UPDATE_TO_TRIAL', updateToTrial);
  yield takeLatest('VERIFY_VINS', verifyVINsSubroutine);
  yield takeLatest(
    'SEND_REFUSAL_TO_WRITE_EMAIL',
    sendRefusalToWriteEmailSubroutine
  );
  yield takeLatest('REQUEST_PREBIND_PDF', prebindPDFWatcher);
  yield takeLatest('SUBMIT_PREBIND_SIGNATURE', watchPrebindSignature);
  yield takeLatest(
    'GET_DYNAMIC_PRE_BIND_FORM_BY_ID',
    getDynamicPreBindFormByIdSubroutine
  );
  yield takeLatest('SUBMIT_FORM_ID_SIGNATURE', submitFormIdSignatureWatcher);
  yield takeLatest(
    'REQUEST_DYNAMIC_PRE_BIND_FORMS',
    requestDynamicPreBindFormsSubroutine
  );
}

function* watchReCaptchaActions() {
  yield takeEvery('VERIFY_RECAPTCHA_TOKEN', recaptchaSubroutine);
  yield takeEvery(
    'GET_QUOTE_AUTOMATION_STATUS',
    determineQuoteAutomationStatus
  );
}
// TODO: Re-enable for sales tool pre-bind purchase flow handoff
// function* watchPreBindFlow() {
//   yield takeLatest('VERIFY_PRE_BIND_USER_INFO', confirmPreBindUserInfo);
//   yield takeLatest(
//     'SEND_PRE_BIND_APPLICATION_SIGNATURE',
//     sendPreBindInsuranceApplicationSignature
//   );
//   yield takeLatest(
//     'SEND_PRE_BIND_MEMBERSHIP_SIGNATURE',
//     sendPreBindMembershipApplicationSignature
//   );
// }

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    spawn(watchAsyncRequests), // TODO: move into App Saga and create subroutine
    fork(watchPersonActions),
    fork(watchAppActions),
    fork(watchDriversActions),
    fork(watchVehiclesActions),
    fork(watchCoverageActions),
    fork(watchPolicyActions),
    fork(watchAuthActions),
    fork(watchReCaptchaActions),
    fork(watchSectionRequests),
    fork(productDataWatcher),
    fork(watchExcludedDriversActions)
  ]);
}
